/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MandateStatus } from './mandateStatus';
import { PermissionType } from './permissionType';
import { CustomerType } from './customerType';


/**
 * Permission customer has granted
 */
export interface GrantedPermission { 
    /**
     * ID
     */
    id?: string;
    /**
     * ID of mandate customer
     */
    mandateCustomerId?: string;
    /**
     * ID of metering point
     */
    meteringPointId?: string;
    mandateCustomerType?: CustomerType;
    permissionType?: PermissionType;
    mandateStatus?: MandateStatus;
    /**
     * Purpose
     */
    purpose?: string;
    /**
     * Participant role type
     */
    participantRoleType?: string;
    /**
     * Subject period from date
     */
    subjectPeriodFrom?: string;
    /**
     * Subject period to date
     */
    subjectPeriodTo?: string;
    /**
     * Validity begin date
     */
    validFrom?: string;
    /**
     * Validity end date
     */
    validTo?: string;
}

