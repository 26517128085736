/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonType } from './personType';


export interface Person { 
    /**
     * Person UUID
     */
    id: string;
    /**
     * Person EIC
     */
    eic?: string;
    /**
     * Person\'s country code
     */
    codeCountry: string;
    /**
     * Person\'s name
     */
    name: string;
    /**
     * Person\'s code
     */
    code: string;
    type: PersonType;
    /**
     * Person trust level
     */
    trustLevel: Person.TrustLevelEnum;
    /**
     * Error ID if person data could not be retrieved
     */
    errorId?: string;
}
export namespace Person {
    export type TrustLevelEnum = 'NONE' | 'LOW' | 'SUBSTANTIAL' | 'HIGH';
    export const TrustLevelEnum = {
        None: 'NONE' as TrustLevelEnum,
        Low: 'LOW' as TrustLevelEnum,
        Substantial: 'SUBSTANTIAL' as TrustLevelEnum,
        High: 'HIGH' as TrustLevelEnum
    };
}


