/* istanbul ignore file */

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'energyPrice', pure: false
})
export class EnergyPricePipe implements PipeTransform {
    static readonly unitTranslationKeys: string[] = ['', '', 'ep.unit.million', 'ep.unit.billion'];
    static readonly divThreshold: number = 1000000;
    static readonly divisor: number = 1000;

    constructor(
        private decimalPipe: DecimalPipe,
        private translateService: TranslateService
    ) {}

    transform(value: number, digitsInfo?: string, locale?: string): string | null {
        if (value === undefined || value === null) {
            return null;
        }
        const formattedValue = this.decimalPipe.transform(EnergyPricePipe.toThreshold(value), digitsInfo, locale);
        const unitTrKey = this.getUnitTranslationKey(value);
        if (unitTrKey.length > 0) {
            const unit = this.translateService?.instant(unitTrKey) || unitTrKey;
            return `${formattedValue} ${unit}`;
        }
        return formattedValue;
    }

    private getUnitTranslationKey(value: number): string {
        if (value < EnergyPricePipe.divThreshold) {
            return '';
        }
        const trKeys = EnergyPricePipe.unitTranslationKeys;
        let currentValue = value;
        let unitIdx = 0;
        while (currentValue !== undefined && currentValue >= EnergyPricePipe.divisor) {
            currentValue /= EnergyPricePipe.divisor;
            unitIdx += 1;
        }
        return unitIdx < trKeys.length ? trKeys[unitIdx] : trKeys[trKeys.length - 1];
    }

    private static toThreshold(value: number): number {
        if (value < EnergyPricePipe.divThreshold) {
            return value;
        }
        let currentValue = value;
        while (currentValue !== undefined && currentValue >= EnergyPricePipe.divisor) {
            currentValue /= EnergyPricePipe.divisor;
        }
        return currentValue;
    }
}
