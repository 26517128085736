/* istanbul ignore file */
/* used for ng-select option collapse */

import {
    Directive, ElementRef, HostListener
} from '@angular/core';

@Directive({
    selector: '[collapse]'
})
export class CollapseDirective {

    constructor(private element: ElementRef) { }

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        event.stopPropagation();
        const current = event.target as HTMLElement;
        let option = current.parentElement.nextElementSibling;
        current.classList.toggle('closed');
        while (option) {
            if (!option.classList.contains('ng-option')) break;
            option.classList.toggle('d-none');
            option = option.nextElementSibling;
        }
    }

}
