/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Metering point source type
 */
export type MeteringPointSourceType = 'AGREEMENT' | 'PERMISSION';

export const MeteringPointSourceType = {
    Agreement: 'AGREEMENT' as MeteringPointSourceType,
    Permission: 'PERMISSION' as MeteringPointSourceType
};

