import { Component, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgForOf } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { FileUploadItemComponent } from './file-upload-item.component';

@Component({
    selector: 'efkp-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    imports: [
        NgForOf,
        SharedModule,
        FileUploadItemComponent,
    ],
    standalone: true
})
export class FileUploadComponent {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;

    files: File[] = [];
    maxFiles = 5;
    maxFileSize = 15 * 1024 * 1024; // 15MB
    allowedExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'csv'];
    errors = {
        maxFilesExceeded: false,
        maxFileSizeExceeded: false,
        invalidExtension: false
    };

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer && event.dataTransfer.files) {
            this.addFiles(event.dataTransfer.files);
        }
    }

    onFileSelected(event: any) {
        if (event.target.files) {
            this.addFiles(event.target.files);
        }
    }

    addFiles(files: FileList) {
        this.errors.maxFilesExceeded = false;
        if (files.length + this.files.length > this.maxFiles) {
            this.errors.maxFilesExceeded = true;
            return;
        }
        Array.from(files)
            .filter((file) => this.validateFile(file))
            .forEach((file) => this.files.push(file));
        this.updateForm();
    }

    validateFile(file: File): boolean {
        this.errors.invalidExtension = false;
        this.errors.maxFileSizeExceeded = false;

        if (!this.allowedExtensions.includes(this.getExtension(file))) {
            this.errors.invalidExtension = true;
            return false;
        }

        const fileSizeValid = this.fileSizeTotal + file.size <= this.maxFileSize;
        if (!fileSizeValid) {
            this.errors.maxFileSizeExceeded = true;
            return false;
        }
        return true;
    }

    private get fileSizeTotal(): number {
        return this.files.reduce((acc, f) => acc + f.size, 0);
    }

    get allowedExtensionsFormatted(): string {
        return this.allowedExtensions.map((ext) => `.${ext}`).join(', ');
    }

    get maxFileSizeInMB(): string {
        return (this.maxFileSize / (1024 * 1024)).toFixed(0);
    }

    getExtension(file: File) {
        return file.name.split('.').pop().toLowerCase();
    }

    removeFile(index: number) {
        this.files.splice(index, 1);
        this.updateForm();
    }

    updateForm() {
        this.formGroup.get(this.controlName).setValue(this.files);
    }
}
