import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '@elering/common';
import { CanDeactivateGuard } from './core/guards/can-deactivate-guard';
import { TermsOfServiceGuard } from './core/guards/terms-of-service-guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '.',
        pathMatch: 'full',
        data: {
            breadcrumb: 'home'
        }
    },
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./views/portal/portal.module').then((m) => m.PortalModule),
                canActivate: [TermsOfServiceGuard]
            },
            {
                path: '',
                loadChildren: () => import('./views/metering-point/metering-point.module').then((m) => m.MeteringPointModule),
                canActivate: [TermsOfServiceGuard]
            },
            {
                path: '',
                loadChildren: () => import('./views/energy-price/energy-price.module').then((m) => m.EnergyPriceModule),
                canActivate: [TermsOfServiceGuard]
            },
            {
                path: '',
                loadChildren: () => import('./views/data-tracker/data-tracker.module').then((m) => m.DataTrackerModule),
                canActivate: [TermsOfServiceGuard]
            },
            {
                path: '',
                loadChildren: () => import('./views/access-permission/access-permission.module').then((m) => m.AccessPermissionModule),
                canActivate: [TermsOfServiceGuard]
            },
            {
                path: '',
                loadChildren: () => import('./views/account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
                canActivate: [TermsOfServiceGuard]
            }
        ]
    },
    { path: '**', redirectTo: '.' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [
        CanDeactivateGuard,
        TermsOfServiceGuard
    ]
})
export class AppRoutingModule {
}
