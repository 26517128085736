import { Component, OnInit, Input } from '@angular/core';
import { MeteringPointLocation } from 'gen_openapi';

@Component({
    selector: 'mp-metering-address',
    templateUrl: './metering-address.component.html',
    styleUrls: ['./metering-address.component.scss']
})
export class MeteringAddressComponent implements OnInit {

    @Input() location: MeteringPointLocation;

    constructor() { }

    ngOnInit(): void {
    }

}
