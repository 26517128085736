import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MeteringPeriodsService } from '../../views/metering-point/services/metering-periods.service';
import { MeteringPeriodDatepickerService, DatepickerMode } from '../../views/metering-point/services/metering-period-datepicker.service';

@Component({
    selector: 'efkp-period-pagination',
    templateUrl: './period-pagination.component.html',
    styleUrls: ['./period-pagination.component.scss']
})
export class PeriodPaginationComponent implements OnInit, OnDestroy {

    public dateFrom: Date;
    public dateTo: Date;
    public datepickerMode: DatepickerMode;
    public format: string;
    public datepickerMinDate: Date;
    public datepickerMaxDate: Date;

    private periodSubscription: Subscription;
    private datepickerSubscription: Subscription;

    constructor(
        private periodService: MeteringPeriodsService,
        private datepickerService: MeteringPeriodDatepickerService
    ) { }

    ngOnInit(): void {
        // get, set current period
        this.periodSubscription = this.periodService.getPeriodRange().subscribe((date) => {
            this.dateFrom = new Date(date.from);
            this.dateTo = new Date(date.to);
        });
        // get, set datepicker config
        this.datepickerSubscription = this.datepickerService.getDatepickerConfig().subscribe((config) => {
            this.datepickerMinDate = config.minDate;
            this.datepickerMaxDate = config.maxDate;
            this.datepickerMode = config.mode;
            this.setFormat(config.mode);
        });
    }

    ngOnDestroy(): void {
        if (this.periodSubscription) {
            this.periodSubscription.unsubscribe();
        }
        if (this.datepickerSubscription) {
            this.datepickerSubscription.unsubscribe();
        }
    }

    public isButtonDisabled(checked: Date): boolean {
        return this.datepickerService.isDisabled(checked, this.dateFrom, this.dateTo);
    }

    private setFormat(mode: DatepickerMode): string {
        if (mode === DatepickerMode.day) {
            this.format = 'EEEE dd.MM.yyyy';
            return;
        }
        if (mode === DatepickerMode.week) {
            this.format = 'dd.MM.yyyy';
            return;
        }
        if (mode === DatepickerMode.month) {
            this.format = 'MMMM yyyy';
            return;
        }
        if (mode === DatepickerMode.year) {
            this.format = 'yyyy';
        }
    }

    public setPreviousPeriod(): void {
        this.datepickerService.setPeriod(this.previousPeriod);
    }

    public setNextPeriod(): void {
        this.datepickerService.setPeriod(this.nextPeriod);
    }

    public get previousPeriod(): Date {
        return this.datepickerService.getPreviousPeriod(this.dateFrom);
    }

    public get nextPeriod(): Date {
        return this.datepickerService.getNextPeriod(this.dateFrom);
    }

    public get DatepickerMode(): typeof DatepickerMode {
        return DatepickerMode;
    }
}
