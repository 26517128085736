import { NgModule } from '@angular/core';

// Shared modules
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { EleringCommonModule } from '@elering/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'ngx-easy-table';

// ngx-bootstrap
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, etLocale } from 'ngx-bootstrap/locale';

// Angular cdk
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Shared components
import { HighchartsChartModule } from 'highcharts-angular';
import { TimeStampToTimePipe } from './pipes/formatSeconds.pipe';
import { LocalizedDatePipe } from './pipes/localizedDate.pipe';
import { EnergyAmountPipe } from './pipes/energyAmount.pipe';
import { EnergyPricePipe } from './pipes/energyPrice.pipe';
import { TimerComponent } from './timer/timer.component';
import { SessionExpiryModalComponent } from './session-expiry-timer/session-expiry-modal.component';
import { SessionExpiredModalComponent } from './session-expiry-timer/session-expired-modal.component';
import { ChartComponent } from './graphs/chart/chart.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { StepperComponent } from './stepper/stepper.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { ContentButtonsComponent } from './content-buttons/content-buttons.component';
import { ExportComponent } from './export/export.component';
import { PeriodPaginationComponent } from './period-pagination/period-pagination.component';
import { NotificationComponent } from './notification/notification.component';

// Metering point components
import {
  MeteringPointSelectComponent
} from '../views/metering-point/shared/metering-point-select/metering-point-select.component';
import {
  MeteringResolutionSelectComponent
} from '../views/metering-point/shared/metering-resolution-select/metering-resolution-select.component';
import { MeteringAddressComponent } from '../views/metering-point/shared/metering-address/metering-address.component';

// Directives
import { NgInitDirective } from './directives/init.directive';
import { TabLinkDirective } from './directives/tab-link.directive';
import { CollapseDirective } from './directives/collapse.directive';
import { ClipboardModule } from "@angular/cdk/clipboard";

defineLocale('et', etLocale);
defineLocale('en', enGbLocale);

const SHARED_MODULES = [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    EleringCommonModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    HighchartsChartModule,
    CdkStepperModule,
    ScrollingModule,
    NgSelectModule,
    TableModule,
    ClipboardModule
];

const COMPONENTS = [
    SessionExpiryModalComponent,
    SessionExpiredModalComponent,
    TimerComponent,
    ChartComponent,
    DatepickerComponent,
    StepperComponent,
    MeteringPointSelectComponent,
    MeteringResolutionSelectComponent,
    MeteringAddressComponent,
    FormDatepickerComponent,
    ContentButtonsComponent,
    ExportComponent,
    PeriodPaginationComponent,
    NotificationComponent
];

const DIRECTIVES = [
    NgInitDirective,
    TabLinkDirective,
    CollapseDirective
];

const PIPES = [
    TimeStampToTimePipe,
    LocalizedDatePipe,
    EnergyAmountPipe,
    EnergyPricePipe
];

@NgModule({
    declarations: [PIPES, DIRECTIVES, COMPONENTS],
    imports: [
        SHARED_MODULES
    ],
    exports: [
        SHARED_MODULES,
        PIPES,
        DIRECTIVES,
        COMPONENTS
    ],
    providers: [DatePipe, LocalizedDatePipe, DecimalPipe, EnergyAmountPipe, EnergyPricePipe]
})
export class SharedModule {}
