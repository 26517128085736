/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Customer type
 */
export type CustomerType = 'PRIVATE' | 'LEGAL' | 'ORGANIZATION';

export const CustomerType = {
    Private: 'PRIVATE' as CustomerType,
    Legal: 'LEGAL' as CustomerType,
    Organization: 'ORGANIZATION' as CustomerType
};

