import {
    Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';

type Size = 'small' | 'large';

@Component({
    selector: 'efkp-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    @Input() lead: string;
    @Input() description: string;
    @Input() icon: string;
    @Input() button: string;
    @Input() size: Size = 'large';
    @Input() darkTheme: boolean;
    @Input() strikethrough: boolean;
    @Input() reload: boolean;

    @Output() onButtonClickEvent: EventEmitter<void> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    public buttonClick(): void {
        if (this.reload) {
            this.reloadWindow();
            return;
        }
        this.onButtonClickEvent.emit();
    }

    private reloadWindow(): void {
        window.location.reload();
    }

}
