import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeteringDataTimeInterval } from 'gen_openapi';
import * as dayjs from 'dayjs';
import * as et from 'dayjs/locale/et';
import { MeteringPeriods } from '../models/metering-periods.enum';

// set dayjs locale
dayjs.locale(et);

// min and max date for period range
export const minDate: string = dayjs().year(2013).startOf('year').toISOString();
export const maxDate: string = dayjs().toISOString();

@Injectable({
    providedIn: 'root'
})
export class MeteringPeriodsService {

    private defaultPeriodType: MeteringPeriods = MeteringPeriods.Week;
    private currentPeriodRange = new BehaviorSubject<MeteringDataTimeInterval>({});
    private currentPeriodType = new BehaviorSubject<MeteringPeriods>(this.defaultPeriodType);

    constructor() {
        // set default period
        this.setPeriod(this.defaultPeriodType);
    }

    public getPeriodRange(): Observable<MeteringDataTimeInterval> {
        return this.currentPeriodRange.asObservable();
    }

    public getPeriodType(): Observable<MeteringPeriods> {
        return this.currentPeriodType.asObservable();
    }

    public getDefaultPeriodType(): MeteringPeriods {
        return this.defaultPeriodType;
    }

    public setPeriod(type: MeteringPeriods, date?: Date[]): void {
        // set period type
        this.currentPeriodType.next(type);
        // set period range
        switch (type) {
            case MeteringPeriods.Day:
                return this.setDay(date);
            case MeteringPeriods.Week:
                return this.setWeek(date);
            case MeteringPeriods.Month:
                return this.setMonth(date);
            case MeteringPeriods.Year:
                return this.setYear(date);
            case MeteringPeriods.Custom:
                return this.setCustom(date);
        }
    }

    private setDay(date?: Date[]): void {
        return this.currentPeriodRange.next({
            from: dayjs(date ? date[0] : undefined).startOf('day').toISOString(),
            to: dayjs(date ? date[1] : undefined).endOf('day').toISOString()
        });
    }

    private setWeek(date?: Date[]): void {
        const startDate = (from: Date) => {
            if (dayjs(from).startOf('week').isBefore(minDate)) {
                return minDate;
            }
            return dayjs(from).startOf('week').toISOString();
        };
        const endDate = (to: Date) => {
            if (dayjs(to).endOf('week').isBefore(maxDate)) {
                return dayjs(to).endOf('week').toISOString();
            }
            return maxDate;
        };
        return this.currentPeriodRange.next({
            from: !date ? dayjs().startOf('week').toISOString() : startDate(date[0]),
            to: !date ? maxDate : endDate(date[1])
        });
    }

    private setMonth(date?: Date[]): void {
        return this.currentPeriodRange.next({
            from: dayjs(date ? date[0] : undefined).startOf('month').toISOString(),
            to: dayjs(date ? date[1] : undefined).endOf('month').toISOString()
        });
    }

    private setYear(date?: Date[]): void {
        return this.currentPeriodRange.next({
            from: dayjs(date ? date[0] : undefined).startOf('year').toISOString(),
            to: dayjs(date ? date[1] : undefined).endOf('year').toISOString()
        });
    }

    private setCustom(date: Date[]): void {
        return this.currentPeriodRange.next({
            from: dayjs(date[0]).startOf('day').toISOString(),
            to: dayjs(date[1]).endOf('day').toISOString()
        });
    }
}
