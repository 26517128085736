/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrantedPermission } from './grantedPermission';
import { Person } from './person';


/**
 * Permissions customer has granted, grouped by person
 */
export interface GrantedPermissions { 
    person?: Person;
    permissions?: Array<GrantedPermission>;
}

