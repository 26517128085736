<ec-select
    id="resolution-select"
    label="mp.resolution.label"
    [options]="resolutionOptions"
    valueField="value"
    displayField="label"
    [allowClear]="false"
    [searchable]="false"
    (ngModelChange)="setResolution($event)"
    [(ngModel)]="currentResolution">
</ec-select>