/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerType } from './customerType';


/**
 * Customer data related to permission creation
 */
export interface PermissionCreationCustomerData { 
    /**
     * Person code
     */
    code?: string;
    /**
     * EIC code
     */
    eic?: string;
    /**
     * Country code
     */
    country?: string;
    type: CustomerType;
    /**
     * Person e-mail address
     */
    email?: string;
}

