import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import * as et from 'dayjs/locale/et';
import { MeteringPeriods } from '../models/metering-periods.enum';
import { MeteringPeriodsService } from './metering-periods.service';

// set dayjs locale
dayjs.locale(et);

export enum DatepickerMode {
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
    ranged = 'ranged'
}

interface DatepickerSubject {
    mode?: DatepickerMode;
    format?: string;
    minDate?: Date;
    maxDate?: Date;
}

@Injectable({
    providedIn: 'root'
})
export class MeteringPeriodDatepickerService {

    private mode: DatepickerMode;
    private format: string;
    private minDate: Date = new Date(dayjs().year(2013).startOf('year').toISOString());
    private maxDate: Date = new Date();

    private subject = new BehaviorSubject<DatepickerSubject>({ minDate: this.minDate, maxDate: this.maxDate });

    constructor(private periodService: MeteringPeriodsService) {
    }

    public setSubject(mode: DatepickerMode) {
        const { minDate } = this;
        const { maxDate } = this;
        const { format } = this;
        this.subject.next({
            mode, minDate, maxDate, format
        });
    }

    public getDatepickerConfig(): Observable<DatepickerSubject> {
        return this.subject.asObservable();
    }

    public setPeriod(from: Date): void {
        const date: Date[] = [new Date(from), new Date(from)];
        if (this.mode === DatepickerMode.day) {
            this.periodService.setPeriod(MeteringPeriods.Day, date);
        }
        if (this.mode === DatepickerMode.week) {
            this.periodService.setPeriod(MeteringPeriods.Week, date);
        }
        if (this.mode === DatepickerMode.month) {
            this.periodService.setPeriod(MeteringPeriods.Month, date);
        }
        if (this.mode === DatepickerMode.year) {
            this.periodService.setPeriod(MeteringPeriods.Year, date);
        }
    }

    public setMaxDate(maxDate: Date): void {
        const { mode, minDate, format } = this;
        this.maxDate = maxDate;
        this.subject.next({
            mode, minDate, maxDate, format
        });
    }

    public setMinDate(minDate: Date):void {
        const { mode, maxDate, format } = this;
        this.minDate = minDate;
        this.subject.next({
            mode, minDate, maxDate, format
        });
    }

    public canSetPeriod(from: Date): boolean {
        if (from > this.maxDate || from < this.minDate) {
            return false;
        }
        return true;
    }

    public getPreviousPeriod(from: Date): Date {
        const date = new Date(from);
        if (this.mode === DatepickerMode.day) {
            const previousDay = date.getDate() - 1;
            date.setDate(previousDay);
        }
        if (this.mode === DatepickerMode.week) {
            const previousWeek = date.getDate() - 7;
            date.setDate(previousWeek);
        }
        if (this.mode === DatepickerMode.month) {
            const previousMonth = date.getMonth() - 1;
            date.setMonth(previousMonth);
        }
        if (this.mode === DatepickerMode.year) {
            const previousYear = date.getFullYear() - 1;
            date.setFullYear(previousYear);
        }
        return date;
    }

    public getNextPeriod(from: Date): Date {
        const date = new Date(from);
        if (this.mode === DatepickerMode.day) {
            const nextDay = date.getDate() + 1;
            date.setDate(nextDay);
        }
        if (this.mode === DatepickerMode.week) {
            const nextWeek = date.getDate() + 7;
            date.setDate(nextWeek);
        }
        if (this.mode === DatepickerMode.month) {
            const nextMonth = date.getMonth() + 1;
            date.setMonth(nextMonth);
        }
        if (this.mode === DatepickerMode.year) {
            const nextYear = date.getFullYear() + 1;
            date.setFullYear(nextYear);
        }
        return date;
    }

    public isDisabled(checked: Date, from?: Date, to?: Date): boolean {
        if (this.mode === DatepickerMode.day) {
            const currentDate = new Date(from).setHours(0, 0, 0, 0);
            const checkedDate = new Date(checked).setHours(0, 0, 0, 0);
            if (currentDate === checkedDate) {
                return true;
            }
        }
        if (this.mode === DatepickerMode.week) {
            const fromDate = new Date(from).setHours(0, 0, 0, 0);
            const toDate = new Date(to).setHours(0, 0, 0, 0);
            const checkedDate = new Date(checked).setHours(0, 0, 0, 0);
            if (fromDate === checkedDate || toDate === checkedDate) {
                return true;
            }
        }
        if (this.mode === DatepickerMode.month) {
            const currentDate = new Date(from);
            const checkedDate = new Date(checked);
            if (checkedDate.getFullYear() === currentDate.getFullYear()
                && checkedDate.getMonth() === currentDate.getMonth()) {
                return true;
            }
        }
        if (this.mode === DatepickerMode.year) {
            const currentDate = new Date(from).getFullYear();
            const checkedDate = new Date(checked).getFullYear();
            if (currentDate === checkedDate) {
                return true;
            }
        }
        return false;
    }

    public setDatepickerMode(mode: DatepickerMode): void {
        switch (mode) {
            case DatepickerMode.day:
                this.setFormat(DatepickerMode.day);
                this.setSubject(DatepickerMode.day);
                this.mode = DatepickerMode.day;
                return;
            case DatepickerMode.week:
                this.setFormat(DatepickerMode.week);
                this.setSubject(DatepickerMode.week);
                this.mode = DatepickerMode.week;
                return;
            case DatepickerMode.month:
                this.setFormat(DatepickerMode.month);
                this.setSubject(DatepickerMode.month);
                this.mode = DatepickerMode.month;
                return;
            case DatepickerMode.year:
                this.setFormat(DatepickerMode.year);
                this.setSubject(DatepickerMode.year);
                this.mode = DatepickerMode.year;
                return;
            case DatepickerMode.ranged:
                this.setFormat(DatepickerMode.week);
                this.setSubject(DatepickerMode.ranged);
                this.mode = DatepickerMode.ranged;
        }
    }

    private setFormat(mode: DatepickerMode): void {
        if (mode === DatepickerMode.day) {
            this.format = 'DD.MM.YYYY';
            return;
        }
        if (mode === DatepickerMode.week) {
            this.format = 'DD.MM.YY';
            return;
        }
        if (mode === DatepickerMode.month) {
            this.format = 'MMMM YYYY';
            return;
        }
        if (mode === DatepickerMode.year) {
            this.format = 'YYYY';
        }
    }
}
