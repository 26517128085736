import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../../services/locale.service';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(value: Date | string, format = 'mediumDate'): string {
        return new DatePipe(this.localeService.locale || 'en').transform(value, format);
    }
}
