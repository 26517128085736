<div class="system-error-message">
    <div class="text">
        <div class="something-s-missing">{{ 'ep.header.somethingIsMissing' | translate }}</div>
        <div class="something-s-missing-text">
            <p>{{ 'ep.header.somethingIsMissingText' | translate }}</p>
        </div>
        <div class="text-wrapper">
            <a href="/">{{ 'ep.link.goBack' | translate }}</a>
        </div>
    </div>
    <img class="illustration" src="assets/images/error-page-illustration.svg" alt="Windmill illustration"/>
</div>
