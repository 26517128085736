/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Permission type
 */
export type PermissionType = 'ACCESS' | 'REPRESENT_FULL' | 'REPRESENT_LIMITED';

export const PermissionType = {
    Access: 'ACCESS' as PermissionType,
    RepresentFull: 'REPRESENT_FULL' as PermissionType,
    RepresentLimited: 'REPRESENT_LIMITED' as PermissionType
};

