<div *ngIf="showStaticErrorPage">
    <efkp-error-page></efkp-error-page>
</div>
<div *ngIf="showApp" [class]="getMenusClass()">
    <ec-alert *ngIf="showNotification"
              class="global-info-notification"
              [content]="getNotificationText()"
              [type]="getType()"
              [closable]="true"
              (closeClick)="notificationClosed()"
              icon="alert-triangle">
    </ec-alert>
    <ec-alert-area
        [multiple]="true"
        name="global-alerts"
    ></ec-alert-area>
    <ec-toaster></ec-toaster>
    <ec-global-loading-mask></ec-global-loading-mask>
    <router-outlet></router-outlet>
</div>
