/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Mandate status
 */
export type MandateStatus = 'PENDING' | 'APPROVED' | 'REJECTED';

export const MandateStatus = {
    Pending: 'PENDING' as MandateStatus,
    Approved: 'APPROVED' as MandateStatus,
    Rejected: 'REJECTED' as MandateStatus
};

