/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HealthResponse { 
    /**
     * Is PARM up
     */
    parmHealthy?: boolean;
    /**
     * Is TEMP up
     */
    tempHealthy?: boolean;
    /**
     * Is GAVP up
     */
    gavpHealthy?: boolean;
    /**
     * Is EAVP up
     */
    eavpHealthy?: boolean;
}

