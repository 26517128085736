<div class="form-dp input-component"
    [ngClass]="{ 'has-danger': ngControl.errors || formGroupError }">
    <label class="form-label label-title">
        <span class="d-block mb-2">{{ label | translate }}</span>
        <span class="text-danger ms-1" *ngIf="required">*</span>
        <input #dateInput
               class="form-control"
               type="text"
               bsDatepicker
               [bsConfig]="dpConfig"
               [(ngModel)]="value"
               [placement]="placement ?? 'bottom'"
               (ngModelChange)="onValueChange($event)"
        />
        <i class="ti ti-calendar right-icon"></i>
    </label>
</div>

<ng-container *ngIf="ngControl && ngControl.errors">
    <div [ngClass]="hasSuccess ? 'text-success' : 'text-danger'" class="mt-1" *ngIf="ngControl.errors.required">
      {{'ec.form.error.required' | translate}}
    </div>
</ng-container>
