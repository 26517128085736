/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackedDataType } from './trackedDataType';
import { LegalBasisType } from './legalBasisType';


export interface TrackedData { 
    performedAt?: string;
    performer?: string;
    dataType?: TrackedDataType;
    legalBasis?: LegalBasisType;
}

