/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Person } from './person';


export interface Representation { 
    /**
     * ID
     */
    id: string;
    person: Person;
    /**
     * Representation type
     */
    representationType: Representation.RepresentationTypeEnum;
    /**
     * Representation validity start date
     */
    validFrom: string;
    /**
     * Representation validity end date
     */
    validTo: string;
    /**
     * Representation roles
     */
    roles: Array<string>;
    /**
     * Whether the representation is active
     */
    active: boolean;
    /**
     * List of metering point identifiers for which this representation applies. If empty, all metering points are allowed.
     */
    meteringPointIds?: Array<string>;
}
export namespace Representation {
    export type RepresentationTypeEnum = 'SELF' | 'BUSINESS_REGISTRY' | 'MANDATE' | 'ELERING';
    export const RepresentationTypeEnum = {
        Self: 'SELF' as RepresentationTypeEnum,
        BusinessRegistry: 'BUSINESS_REGISTRY' as RepresentationTypeEnum,
        Mandate: 'MANDATE' as RepresentationTypeEnum,
        Elering: 'ELERING' as RepresentationTypeEnum
    };
}


