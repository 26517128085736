/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MandatePurpose } from './mandatePurpose';
import { PermissionType } from './permissionType';
import { ValidityTime } from './validityTime';
import { PermissionCreationCustomerData } from './permissionCreationCustomerData';
import { MandateParticipantRoleType } from './mandateParticipantRoleType';


/**
 * Permission customer has granted
 */
export interface PermissionCreationData { 
    customer: PermissionCreationCustomerData;
    /**
     * Internal IDs of metering points
     */
    meteringPointId: Array<string>;
    permissionType: PermissionType;
    purpose: MandatePurpose;
    participantRoleType: MandateParticipantRoleType;
    validity: ValidityTime;
    subjectPeriod: ValidityTime;
}

