import {
    Directive, OnInit, Output, EventEmitter
} from '@angular/core';

@Directive({
    selector: '[ngInit]'
})
export class NgInitDirective implements OnInit {

    @Output()
    ngInit: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        this.ngInit.emit();
    }
}
