import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeteringResolution } from 'gen_openapi';

@Injectable({
    providedIn: 'root'
})
export class MeteringResolutionService {

    private defaultResolution: MeteringResolution = MeteringResolution.OneDay;
    private currentResolution = new BehaviorSubject<MeteringResolution>(this.defaultResolution);

    constructor() { }

    public getResolution(): Observable<MeteringResolution> {
        return this.currentResolution.asObservable();
    }

    public getDefaultResolution(): MeteringResolution {
        return this.defaultResolution;
    }

    public setResolution(value: MeteringResolution): void {
        this.currentResolution.next(value);
    }
}
