<ec-button
    #popoverRef1="bs-popover"
    placement="bottom"
    [popover]="disabled === false ? customPopover : null"
    [popoverContext]="{ref: popoverRef1}"
    [disabled]="disabled"
    outsideClick="true"
    type="secondary"
    (onShown)="toggleIsOpen()"
    (onHidden)="toggleIsOpen()">
    {{'mp.export.button.download' | translate}}
    <ng-container [ngSwitch]="isOpen">
        <ng-container *ngSwitchCase="true">
            <ec-icon icon="chevron-up"></ec-icon>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ec-icon icon="chevron-down"></ec-icon>
        </ng-container>
    </ng-container>
</ec-button>

<ng-template #customPopover let-ref="ref">
    <ec-popover>
        <ul>
            <li>
                <a href="javascript:void(0)" (click)="exportExcel()">
                    <span>{{'mp.export.button.excel' | translate}}</span>
                    <ec-icon icon="download"></ec-icon>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="exportCsv()">
                    <span>{{'mp.export.button.csv' | translate}}</span>
                    <ec-icon icon="download"></ec-icon>
                </a>
            </li>
            <li *ngIf="allowCustomPeriod && !withoutPeriod">
                <a href="javascript:void(0)" (click)="openModal(modal, 'md')">
                    <span>{{'mp.export.button.custom' | translate}}</span>
                    <ec-icon icon="download"></ec-icon>
                </a>
            </li>
        </ul>
    </ec-popover>
</ng-template>

<ng-template #modal>
    <ec-modal
    (closeClick)="closeModal()">
        <ng-container *ngIf="!showLoader; else exportLoader">
            <div class="row">
                <ec-alert *ngIf="validationError" type="danger" [closable]="false" content="{{validationError | translate}}"></ec-alert>
            </div>
            <div class="row">
                <div class="col-12 col-sm-5 mt-1 export-dp">
                    <efkp-datepicker
                        [period]="currentPeriod"
                        [minDate]="datepickerMinDate"
                        [maxDate]="datepickerMaxDate"
                        format="DD.MM.YYYY"
                        mode="ranged"
                        placement="right"
                        (setDateEvent)="setDate($event)">
                    </efkp-datepicker>
                </div>
                <div class="col-12 col-sm">
                    <mp-metering-resolution-select
                        [energyType]="energyType"
                        [emit]="true"
                        [resolutionFilter]="showResolution"
                        (setResolutionEvent)="setResolution($event)">
                    </mp-metering-resolution-select>
                </div>
            </div>
            <div class="row mt-2" *ngIf="showMarketPriceCheckbox">
                <div class="col">
                    <ec-checkbox
                        label="mp.label.with.energy.price"
                        (check)="showMarketPriceClicked($event)"
                    ></ec-checkbox>
                    <ec-icon
                        icon="info-circle"
                        tooltip="{{'mp.tooltip.energy.price' | translate}}"
                        placement="bottom">
                    </ec-icon>
                </div>
            </div>
            <footer>
                <div class="row">
                    <div class="col-auto text-start">
                        <ec-button (click)="closeModal()" type="secondary">
                            <span>{{'mp.export.modal.button.close' | translate}}</span>
                        </ec-button>
                    </div>
                    <div class="col text-end">
                        <ec-button (click)="exportExcel()">
                            <span>{{'mp.export.modal.button.excel' | translate}}</span>
                            <ec-icon icon="download" class="ms-2"></ec-icon>
                        </ec-button>
                        <ec-button (click)="exportCsv()">
                            <span>{{'mp.export.modal.button.csv' | translate}}</span>
                            <ec-icon icon="download" class="ms-2"></ec-icon>
                        </ec-button>
                    </div>
                </div>
            </footer>
        </ng-container>
    </ec-modal>
</ng-template>

<ng-template #exportLoader>
    <ec-spinner></ec-spinner>
</ng-template>
