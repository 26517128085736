/* eslint-disable */
/* istanbul ignore file */

import { Injectable, OnDestroy } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { SessionExpiryConstants } from '../shared/session-expiry-timer/session-expiry.constants';
import { SessionExpiryModalComponent } from '../shared/session-expiry-timer/session-expiry-modal.component';
import { AppService } from './app.service';
import { AuthService } from '@elering/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '@elering/common';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionExpiryService implements OnDestroy {
    subscriptions = [];
    modalRef: BsModalRef;

    constructor(private modalService: ModalService,
                private authService: AuthService,
                private appService: AppService,
                private idle: Idle,
                private router: Router) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    init(): void {
        this.idle.setIdle(this.appService.appSettings.sessionExpiryWarningTimeout); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(1); // how long can they be idle before considered timed out, in seconds
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

        /*
            // do something when the user becomes idle
            this.idle.onIdleStart.subscribe(() => { });

            // do something when the user is no longer idle
            this.idle.onIdleEnd.subscribe(() => { });

            // do something as the timeout countdown does its thing
            this.idle.onTimeoutWarning.subscribe(seconds => {});
        */

        this.idle.onTimeout.subscribe(() => {
            if (this.authService.isAuthenticated()) {
                this.displaySessionExpiryWarningDialog();
            } else {
                this.idle.watch();
            }
        });

        this.idle.watch();
    }

    displaySessionExpiryWarningDialog(): void {
        const modalOptions: object = { backdrop: 'static', ignoreBackdropClick: true, class: 'modal-sm' };
        this.modalRef = this.modalService.showModal({
            content: SessionExpiryModalComponent,
            modalOptions: modalOptions,
            onHide: (reason) => {
                if (reason) {
                    this.idle.watch();
                    if (reason === SessionExpiryConstants.LOGOUT) {
                        this.navigateToLogout();
                    } else if (reason === SessionExpiryConstants.EXTEND) {
                        this.renewSession();
                    }
                }
            }
        });
    }

    navigateToLogout(): void {
        sessionStorage.setItem('logoutRedirect', this.router.url)
        this.authService.performLogout();
    }

    renewSession(): void {
        this.subscriptions.push(this.authService.getPersonDetails().subscribe());
    }
}
