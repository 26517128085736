/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnergyPriceAllOf } from './energyPriceAllOf';
import { EnergyPriceValue } from './energyPriceValue';


export interface EnergyPrice { 
    /**
     * Price in cents/kWh
     */
    centsPerKwh: number;
    /**
     * Price in cents/kWh with VAT that was valid at the `timestampFrom` time.
     */
    centsPerKwhWithVat: number;
    /**
     * Price in eur/MWh
     */
    eurPerMwh: number;
    /**
     * Price in eur/MWh with VAT that was valid at the `timestampFrom` time.
     */
    eurPerMwhWithVat: number;
    /**
     * Beginning date and time (inclusive) of the energy price period
     */
    fromDateTime: string;
    /**
     * End date and time (inclusive) of the energy price period
     */
    toDateTime: string;
}

