import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class DataHubService {
    constructor(private appService: AppService) {
    }

    public newDataHubEnabled(): boolean {
        return this.appService.appSettings?.features?.newDataHubEnabled ?? false;
    }
}
