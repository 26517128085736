/* istanbul ignore file */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private appLocale: string;

    set locale(value: string) {
        this.appLocale = value;
    }
    get locale(): string {
        return this.appLocale || 'en';
    }

    public registerLocale(locale: string) {
        if (!locale) {
            return;
        }
        switch (locale) {
            case 'et': {
                this.appLocale = 'et';
                break;
            }
            default: {
                this.appLocale = 'en';
                break;
            }
        }
    }
}
