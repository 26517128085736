/* istanbul ignore file */
/* eslint-disable */

import { Injectable, Renderer2, Inject, ApplicationRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from './app.service';
import { tap, first, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private appService: AppService,
        private router: Router
    ) { }

    init(renderer: Renderer2): void {
        if (!this.appService.appSettings?.gaTrackingId) {
            return;
        }
        const script = renderer.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.appService.appSettings.gaTrackingId}`;
        script.onload = () => {
            window['dataLayer'] = window['dataLayer'] || [];
            window['gtag'] = function() {
                window['dataLayer'].push(arguments);
            };
            window['gtag']('js', new Date());
            window['gtag']('config', this.appService.appSettings.gaTrackingId);
        }
        script.onerror = (error) => {
            console.error('Error loading Google Analytics script', error);
        }
        renderer.appendChild(this.document.head, script);
    }
}
