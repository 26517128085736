/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Metering data filtering result
 */
export type MeteringDataFilteringResult = 'NONE' | 'PARTIAL' | 'FULL';

export const MeteringDataFilteringResult = {
    None: 'NONE' as MeteringDataFilteringResult,
    Partial: 'PARTIAL' as MeteringDataFilteringResult,
    Full: 'FULL' as MeteringDataFilteringResult
};

