import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@elering/common';
import { TermsOfServiceService } from 'src/app/services/terms-of-service.service';
import { StateStorageService } from "../../services/state-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TermsOfServiceGuard implements CanActivate {

  constructor(
    private termsOfService: TermsOfServiceService,
    private authService: AuthService,
    private router: Router,
    private stateStorageService: StateStorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === '/terms-of-service') {
      return true;
    }
    if (this.authService.isAuthenticated() && this.termsOfService.shouldNotifyUser()) {
      this.stateStorageService.storeUrl(state.url);
      this.router.navigate(['/terms-of-service']);
      return false;
    }
    return true;
  }
}
