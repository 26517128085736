/* istanbul ignore file */

import {
    Directive, Input, HostListener, OnInit, OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Location } from '@angular/common';
import { Subscription, asapScheduler } from 'rxjs';

@Directive({
    selector: '[tabLink]'
})
export class TabLinkDirective implements OnInit, OnDestroy {

    @Input() tabLink: TabsetComponent;
    @Input() defaultTabId: string;

    private subscription: Subscription;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private location: Location
    ) { }

    ngOnInit(): void {
        this.subscription = this.route.params.subscribe((data) => {
            if (!data.tab && this.defaultTabId) {
                this.router.navigate([this.defaultTabId], { relativeTo: this.route, replaceUrl: true });
            } else {
                asapScheduler.schedule(() => this.setActiveTab());
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    setActiveTab(): void {
        if (!this.tabLink) {
            return;
        }
        const tab = this.route.snapshot.paramMap.get('tab');
        for (let id = 0; id <= this.tabLink?.tabs.length; id += 1) {
            if (this.tabLink?.tabs[id]?.id === tab) {
                this.tabLink.tabs[id].active = true;
                return;
            }
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        const element = event.composedPath().find((e: HTMLElement) => e.classList?.contains('nav-link')) as HTMLElement;
        if (element) {
            this.onTabSelected(element.id.split('-link')[0]);
        }
    }

    onTabSelected(id: string): void {
        const url = this.route.snapshot.paramMap.get('tab') ? `../${id}` : `${id}`;
        this.router.navigate([url], { relativeTo: this.route }).then(() => window.scrollTo({ top: window.pageYOffset }));
    }

}
