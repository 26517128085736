import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CommonTranslateHttpLoader implements TranslateLoader {

    constructor(
        private httpClient: HttpClient
    ) { }

    getTranslation(lang: string): Observable<Record<string, string>> {
        const requests = [];

        this.getFolderNames().forEach((folderName: string) => {
            requests.push(this.httpClient.get(`assets/i18n/${folderName}/${lang}.json`));
        });

        return forkJoin(requests)
            .pipe(
                map((results) => {
                    let translations = {};
                    results.forEach((result) => {
                        translations = Object.assign(translations, result);
                    });
                    return translations;
                })
            );
    }

    protected getFolderNames(): string[] {
        return ['common', 'portal', 'metering-point', 'access-permission',
            'energy-price', 'data-tracker', 'account-settings', 'error-page'];
    }
}
