import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'efkp-file-upload-item',
    templateUrl: './file-upload-item.component.html',
    styleUrls: ['./file-upload-item.component.css'],
    standalone: true,
    imports: [
        NgForOf,
        SharedModule
    ]
})
export class FileUploadItemComponent {
    @Input() file: File;
    @Input() index: number;
    @Output() remove = new EventEmitter<number>();

    get icon() {
        switch (this.file.type) {
            case 'image/png':
            case 'image/jpeg':
                return 'photo';
            case 'application/pdf':
                return 'file-pdf';
            default:
                return 'file';
        }
    }
}
