<div class="notify {{size}}" [ngClass]="{darkTheme}">
    <div class="icon" [ngClass]="{strikethrough}">
        <span class="{{icon}}"></span>
    </div>
    <p *ngIf="lead" class="lead mb-4">{{lead | translate}}</p>
    <p *ngIf="button">
        <button class="btn btn-primary" (click)="buttonClick()">{{button | translate}}</button>
    </p>
    <p *ngIf="description" class="description" [innerHTML]="description | translate"></p>
</div>
