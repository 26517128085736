/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Energy price values cents/kWh, eur/MWh with and without VAT.
 */
export interface EnergyPriceValue { 
    /**
     * Price in cents/kWh
     */
    centsPerKwh: number;
    /**
     * Price in cents/kWh with VAT that was valid at the `timestampFrom` time.
     */
    centsPerKwhWithVat: number;
    /**
     * Price in eur/MWh
     */
    eurPerMwh: number;
    /**
     * Price in eur/MWh with VAT that was valid at the `timestampFrom` time.
     */
    eurPerMwhWithVat: number;
}

