/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AggregationAgreement } from './aggregationAgreement';
import { MeteringPointSource } from './meteringPointSource';
import { SupplyAgreement } from './supplyAgreement';
import { EnergyType } from './energyType';
import { MeteringPointLocation } from './meteringPointLocation';
import { MeteringPointType } from './meteringPointType';
import { MeteringType } from './meteringType';
import { GridAgreement } from './gridAgreement';
import { MeteringPointMetadata } from './meteringPointMetadata';


export interface MeteringPoint { 
    /**
     * Metering point internal ID
     */
    id: string;
    source: MeteringPointSource;
    energyType: EnergyType;
    meteringPointType?: MeteringPointType;
    meteringType?: MeteringType;
    /**
     * Metering point identification code
     */
    eic: string;
    /**
     * Consumption type
     */
    consumptionType: string;
    /**
     * Consumption scale
     */
    consumptionScale: string;
    /**
     * Consumption resolution
     */
    resolution?: string;
    /**
     * Has production
     */
    production?: boolean;
    /**
     * Energy production source
     */
    productionSource?: string;
    /**
     * In apartment association
     */
    apartmentAssociation?: boolean;
    /**
     * Connection state
     */
    connectionState: string;
    /**
     * Electric heating
     */
    elHeating: boolean;
    /**
     * Has charging point
     */
    chargingPoint?: boolean;
    /**
     * Storage capacity
     */
    storageCapacity?: string;
    /**
     * Storage energy
     */
    storageEnergy?: string;
    /**
     * Production capacity
     */
    productionCapacity?: string;
    /**
     * Transmission capacity
     */
    transmissionCapacity?: string;
    location: MeteringPointLocation;
    metadata?: MeteringPointMetadata;
    /**
     * Grid agreements
     */
    gridAgreements: Array<GridAgreement>;
    /**
     * Supply agreements
     */
    supplyAgreements: Array<SupplyAgreement>;
    /**
     * Aggregation agreements
     */
    aggregationAgreements?: Array<AggregationAgreement>;
}

