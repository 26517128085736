/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Create API key
 */
export interface CreateApiKey { 
    name?: string;
    validFrom?: string;
    validTo?: string;
    meteringPointEics?: Array<string>;
}

