/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrantedPermission } from './grantedPermission';


/**
 * Permission creation result. Either the granted permission or an error, if the permission could not be created.
 */
export interface PermissionCreationResult { 
    /**
     * Metering point internal ID
     */
    meteringPointId: string;
    permission?: GrantedPermission;
    error?: Error;
}

