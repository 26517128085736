<div class="d-flex">
    <a href="javascript:void(0)"
        (click)="setPrevDate()"
        class="link-icon"
        [ngClass]="{disabled : isButtonDisabled(datepickerMinDate)}"
       [attr.aria-disabled]="isButtonDisabled(datepickerMinDate)"
       [attr.aria-label]="'mp.label.previousDateUnit' | translate"
        *ngIf="isButtonDisabled(datepickerMinDate) === false">
        <ec-icon icon="chevron-left"></ec-icon>
    </a>
    <div class="input-component">
        <label class="form-label label-title d-block">
            <span class="d-block mb-2">
                {{'mp.label.date.' + datepickerMode | translate}}
            </span>
            <ng-container [ngSwitch]="datepickerMode">
                <ng-container *ngSwitchCase="DatepickerMode.week">
                    <input type="text"
                        class="form-control"
                        [(ngModel)]="datepickerRangePeriod"
                        (ngModelChange)="setRangeDate($event)"
                        bsDaterangepicker
                        [minMode]="datepickerMode"
                        [minDate]="datepickerMinDate"
                        [maxDate]="datepickerMaxDate"
                        [disabled]="datepickerDisabled"
                        [placement]="placement"
                        [bsConfig]="{rangeInputFormat: datepickerFormat, displayMonths: 1, selectWeekDateRange: true, containerClass: 'week-dp'}"
                        (onShown)="weekOnShow()"
                        (onHidden)="weekOnHide()"/>
                </ng-container>
                <ng-container *ngSwitchCase="DatepickerMode.ranged">
                    <input type="text"
                        class="form-control"
                        [(ngModel)]="datepickerRangePeriod"
                        (ngModelChange)="setRangeDate($event)"
                        bsDaterangepicker
                        [minMode]="datepickerMode"
                        [minDate]="datepickerMinDate"
                        [maxDate]="datepickerMaxDate"
                        [disabled]="datepickerDisabled"
                        [placement]="placement"
                        [bsConfig]="{rangeInputFormat: datepickerFormat}"/>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <input type="text"
                        class="form-control"
                        [(ngModel)]="datepickerPeriod"
                        (ngModelChange)="setDate($event)"
                        bsDatepicker
                        [minMode]="datepickerMode"
                        [minDate]="datepickerMinDate"
                        [maxDate]="datepickerMaxDate"
                        [disabled]="datepickerDisabled"
                        [placement]="placement"
                        [bsConfig]="{dateInputFormat: datepickerFormat}"/>
                </ng-container>
            </ng-container>
        </label>
        <i class="ti ti-calendar right-icon"></i>
    </div>
    <a  href="javascript:void(0)"
        (click)="setNextDate()"
        class="link-icon"
        [ngClass]="{disabled : isButtonDisabled(datepickerMaxDate)}"
        [attr.aria-disabled]="isButtonDisabled(datepickerMaxDate)"
        [attr.aria-label]="'mp.label.nextDateUnit' | translate"
        *ngIf="isButtonDisabled(datepickerMinDate) === false">
        <ec-icon icon="chevron-right"></ec-icon>
    </a>
</div>
