<svg style="height: 0">
    <defs>
        <linearGradient id="gradient-0" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" />
            <stop offset="1" />
        </linearGradient>
        <linearGradient id="gradient-1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" />
            <stop offset="1" />
        </linearGradient>
        <pattern id="pattern1" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(0)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern2" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(90)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern3" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(-45)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern4" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern5" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(0)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
            <line x1="4" y1="0" x2="4" y2="6" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern6" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect x="0" y="0" width="6" height="6" fill="white" stroke="none"/>
            <line x1="0" y1="2" x2="6" y2="2" stroke="black" stroke-width="2"/>
            <line x1="4" y1="0" x2="4" y2="6" stroke="black" stroke-width="2"/>
        </pattern>
        <pattern id="pattern7" width="12" height="12" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="12" height="12" fill="white" stroke="none"/>
            <rect x="0" y="0" width="4" height="4" fill="black" stroke="none"/>
            <rect x="6" y="6" width="4" height="4" fill="black" stroke="none"/>
        </pattern>
        <pattern id="pattern8" width="4" height="4" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" fill="white" stroke="none"/>
            <rect x="0" y="0" width="2" height="2" fill="black" stroke="none"/>
            <rect x="2" y="2" width="2" height="2" fill="black" stroke="none"/>
        </pattern>
        <pattern id="pattern9" width="16" height="16" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="16" height="16" fill="white" stroke="none"/>
            <circle cx="4" cy="4" r="3" fill="black" stroke="none"/>
            <circle cx="12" cy="12" r="3" fill="black" stroke="none"/>
        </pattern>
    </defs>
</svg>

<highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [(update)]="update"
    [oneToOne]="true"
    style="width: 100%; height: 400px; display: block;"
></highcharts-chart>
