import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../models/app-settings';

@Injectable({ providedIn: 'root' })
export class AppService {
  appSettings = new AppSettings();

  private http = inject(HttpClient);

  initAppSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>('/api/public/v1/settings').pipe(
      tap({
        next: (settings) => this.appSettings = settings,
        error: () => this.appSettings = new AppSettings()
      })
    );
  }
}
