import {
    APP_INITIALIZER, Injector, LOCALE_ID, NgModule
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeEt from '@angular/common/locales/et';

// Elering commons
import {
    AuthService, DashboardService, HelpClickHandler, UserContextService
} from '@elering/common';

// ngx-translate
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

// Openapi configuration
import { ApiModule, Configuration, ConfigurationParameters } from 'gen_openapi';
import { environment } from '../../environments/environment';

import { HttpRequestInterceptor } from './providers/request-interceptor';
import { HttpErrorInterceptor } from './providers/error-interceptor';
import { HttpRequestEncoder } from './providers/request-encoder';
import { CommonTranslateHttpLoader } from './translate/translate-http.loader';
import { AppService } from '../services/app.service';
import { TermsOfServiceService } from '../services/terms-of-service.service';

// Custom services
import { CustomAuthService } from '../overrides/custom-auth.service';
import { CustomUserContextService } from '../overrides/custom-user-context.service';
import { CustomDashboardService } from '../overrides/custom-dashboard.service';
import { CustomHelpClickHandler } from '../overrides/custom-help-click-handler.service';

registerLocaleData(localeEt);

export function ApiConfig(): Configuration {
    const params: ConfigurationParameters = {
        // set configuration parameters here.
        basePath: environment.API_BASE_PATH
    };
    return new Configuration(params);
}

export function localizationInitializerFactory(translateService: TranslateService, injector: Injector): () => void {
    return () => new Promise<void>((resolve) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {

            const lang = localStorage.getItem('language') || 'et';
            translateService.setDefaultLang(lang);

            translateService.use(lang).subscribe({
                error: () => {
                    console.error(`Problem with '${lang}' language initialization.'`); // eslint-disable-line
                },
                complete: () => {
                    resolve(null);
                }
            });
        });
    });
}

export function appSettingsInitializerFactory(appService: AppService): () => void {
    /**
     * Currently only gaTrackingId is stored in settings, failure to get settings should not result in app failure
     */
    return () => new Promise<void>((resolve) => {
        appService.initAppSettings().subscribe({
            next: () => resolve(),
            error: () => {
                console.error('Unable to get application settings!'); // eslint-disable-line
                resolve();
            }
        });
    });
}

export function termsOfServiceInitializerFactory(tosService: TermsOfServiceService): () => void {
    return () => new Promise<void>((resolve) => {
        tosService.initLastUpdatedDate().subscribe({
            next: () => resolve(),
            error: () => {
                console.error('Unable to get terms of service!'); // eslint-disable-line
                resolve();
            }
        });
    });
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http) => new CommonTranslateHttpLoader(http),
                deps: [HttpClient]
            }
        }),
        ApiModule.forRoot(ApiConfig)
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: localizationInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: appSettingsInitializerFactory,
            deps: [AppService],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: termsOfServiceInitializerFactory,
            deps: [TermsOfServiceService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestEncoder,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => translate.currentLang,
            deps: [TranslateService],
        },
        {
            provide: UserContextService,
            useExisting: CustomUserContextService
        },
        {
            provide: AuthService,
            useExisting: CustomAuthService
        },
        {
            provide: DashboardService,
            useExisting: CustomDashboardService
        },
        {
            provide: HelpClickHandler,
            useExisting: CustomHelpClickHandler
        }
    ]
})
export class CoreModule {
}
