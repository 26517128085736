import { Component } from '@angular/core';
import {
    AuthService, EleringCommonModule, ModalService, UserContextService
} from '@elering/common';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomerSupportUserCardComponent } from './user-card/customer-support-user-card.component';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {
    CreateCustomerSupportRequest,
    CustomerSupportRequestReason,
    CustomerSupportService,
    PersonType
} from '../../../../gen_openapi';
import { User } from './user';

interface SelectOption {
    id: string;
    name: string;
}

@Component({
    selector: 'ec-customer-support-modal',
    templateUrl: './customer-support-modal.component.html',
    imports: [
        NgIf,
        CustomerSupportUserCardComponent,
        EleringCommonModule,
        TranslateModule,
        SharedModule,
        FileUploadComponent
    ],
    standalone: true
})
export class CustomerSupportModalComponent {

    form = this.fb.group({
        reason: [null, Validators.required],
        content: [null, Validators.required],
        email: [null, Validators.compose([Validators.required, Validators.email])],
        files: []
    });
    reasons: SelectOption[] = Object.values(CustomerSupportRequestReason).map((value) => ({
        id: value,
        name: `customerSupport.reason.${value}`
    }));
    submitted = false;

    constructor(
        public modal: ModalService,
        private auth: AuthService,
        private userContext: UserContextService,
        private service: CustomerSupportService,
        private fb: UntypedFormBuilder
    ) {}

    get user(): User {
        const representedPerson = this.userContext.getRepresentedPerson();
        if (representedPerson.type === PersonType.Legal) {
            return {
                name: representedPerson.name,
                code: representedPerson.code
            };
        }
        const userData = this.auth.getUserData();
        return {
            name: userData.name,
            code: userData.code
        };
    }

    get loggedIn(): boolean {
        return this.auth.isAuthenticated();
    }

    submit() {
        this.markFieldsAsTouchedAndDirty();
        if (this.form.invalid) {
            return;
        }
        const request: CreateCustomerSupportRequest = {
            reason: this.form.controls.reason.value,
            content: this.form.controls.content.value,
            email: this.form.controls.email.value
        };
        this.service.createCustomerSupportRequest(request, this.form.controls.files.value).subscribe(() => {
            this.submitted = true;
        });
    }

    markFieldsAsTouchedAndDirty(): void {
        Object.values(this.form.controls).forEach((ctrl) => {
            ctrl.markAsTouched();
            ctrl.markAsDirty();
        });
    }

    closeModal() {
        this.modal.closeActiveModal();
    }
}
