/* istanbul ignore file */

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponseHandler } from '../../services/error-handler.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private errorHandler: ErrorResponseHandler) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
            this.errorHandler.handleError(error);
            return throwError(error);
        }));
    }
}
