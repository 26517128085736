/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Agreement status
 */
export type AgreementStatus = 'VALID_IN_FUTURE' | 'VALID' | 'EXPIRED';

export const AgreementStatus = {
    ValidInFuture: 'VALID_IN_FUTURE' as AgreementStatus,
    Valid: 'VALID' as AgreementStatus,
    Expired: 'EXPIRED' as AgreementStatus
};

