<div class="row period-pagination" *ngIf="datepickerMode !== DatepickerMode.ranged">
    <div class="col-auto">
        <a  href="javascript:void(0)"
            (click)="setPreviousPeriod()"
            class="link-icon"
            [ngClass]="{disabled : isButtonDisabled(datepickerMinDate)}"
            [attr.aria-disabled]="isButtonDisabled(datepickerMinDate)">
            <ec-icon icon="chevron-left"></ec-icon>
            <span>{{previousPeriod | localizedDate: format}}</span>
        </a>
    </div>
    <div class="col text-center">
        <ng-container [ngSwitch]="datepickerMode">
            <ng-container *ngSwitchCase="DatepickerMode.week">
                <p class="title">{{dateFrom | localizedDate: format}} - {{dateTo | localizedDate: format}}</p>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <p class="title">{{dateFrom | localizedDate: format}}</p>
            </ng-container>
        </ng-container>
    </div>
    <div class="col-auto">
        <a  href="javascript:void(0)"
            (click)="setNextPeriod()"
            class="link-icon"
            [ngClass]="{disabled : isButtonDisabled(datepickerMaxDate)}"
            [attr.aria-disabled]="isButtonDisabled(datepickerMaxDate)">
            <span>{{nextPeriod | localizedDate: format}}</span>
            <ec-icon icon="chevron-right"></ec-icon>
        </a>
    </div>
</div>
