/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackedData } from './trackedData';
import { TrackedDataResponsePaging } from './trackedDataResponsePaging';


export interface TrackedDataResponse { 
    items?: Array<TrackedData>;
    paging?: TrackedDataResponsePaging;
}

