<div class="container">
    <p class="title text-uppercase">{{ 'customerSupport.userCardTitle' | translate }}</p>
    <div class="d-flex">
        <ec-icon icon="user" class="icon"></ec-icon>
        <div class="details">
            <div class="name">{{ user.name }}</div>
            <div class="id">{{ user.code }}</div>
        </div>
    </div>
</div>
