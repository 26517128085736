/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Legal basis for data query
 */
export type LegalBasisType = 'ACCESS' | 'REPRESENT' | 'AGREEMENT' | 'LEGISLATION' | 'LEGAL_CONSENT';

export const LegalBasisType = {
    Access: 'ACCESS' as LegalBasisType,
    Represent: 'REPRESENT' as LegalBasisType,
    Agreement: 'AGREEMENT' as LegalBasisType,
    Legislation: 'LEGISLATION' as LegalBasisType,
    LegalConsent: 'LEGAL_CONSENT' as LegalBasisType
};

