/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeteringPointTag } from './meteringPointTag';


/**
 * Metering point metadata (name, tags, etc.)
 */
export interface MeteringPointMetadata { 
    /**
     * ID
     */
    id?: string;
    /**
     * Metering point name
     */
    name?: string;
    /**
     * Metering point tags
     */
    tags?: Array<MeteringPointTag>;
}

