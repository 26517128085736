<div class="drop-area"
     (click)="fileInput.click()"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     (drop)="onDrop($event)">
    <input type="file" [accept]="allowedExtensionsFormatted" #fileInput (change)="onFileSelected($event)"
           hidden multiple>
    <div class="file-drop-message">
        <ec-icon icon="file-upload" class="file-drop-icon"></ec-icon>
        <div>{{ 'customerSupport.files.description' | translate }}</div>
        <div class="file-size">{{ 'customerSupport.files.maxSize' | translate: { maxFileSize: maxFileSizeInMB } }}
        </div>
    </div>
</div>
<div class="file-list mt-2">
    <efkp-file-upload-item *ngFor="let file of files; index as i"
                           [file]="file" [index]="i"
                           (remove)="removeFile($event)">
    </efkp-file-upload-item>
</div>
<div class="error mt-1">
    <div *ngIf="errors.maxFileSizeExceeded" class="text-danger">
        {{ 'customerSupport.files.maxFileSizeExceeded' | translate: { maxFileSize: maxFileSizeInMB } }}
    </div>
    <div *ngIf="errors.invalidExtension" class="text-danger">
        {{ 'customerSupport.files.invalidExtension' | translate: { allowedExtensions: allowedExtensionsFormatted } }}
    </div>
    <div *ngIf="errors.maxFilesExceeded" class="text-danger">
        {{ 'customerSupport.files.maxFilesExceeded' | translate: { maxFiles } }}
    </div>
</div>
