/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EnergyPriceAllOf { 
    /**
     * Beginning date and time (inclusive) of the energy price period
     */
    fromDateTime: string;
    /**
     * End date and time (inclusive) of the energy price period
     */
    toDateTime: string;
}

