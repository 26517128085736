/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Service provider
 */
export interface ServiceProvider { 
    /**
     * Service provider identifier
     */
    id: string;
    /**
     * Service provider name
     */
    companyName: string;
    /**
     * Service provider eic
     */
    eic?: string;
    /**
     * Service provider application name
     */
    applicationName: string;
    /**
     * Link to the service provider
     */
    link?: string;
    /**
     * Image of the service provider
     */
    image?: string;
    /**
     * Service provider mandate purpose
     */
    purpose?: string;
    /**
     * Service provider mandate participant role type
     */
    participantRoleType?: string;
}

