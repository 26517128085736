import * as dayjs from 'dayjs';
import { CustomUserContextService } from './overrides/custom-user-context.service';

export class Utils {

    static hasRepresentee(customUserCtx: CustomUserContextService): boolean {
        const representedPerson = customUserCtx.getRepresentedPerson();
        if (representedPerson) {
            return true;
        }
        return false;
    }

    static getRepresentation(): string {
        const representationStr = sessionStorage.getItem('representation');
        if (representationStr) {
            return representationStr;
        }
        return null;
    }

    static compareFn(value: unknown): string {
        if (value === undefined) return '';
        if (typeof value === 'string') return value;

        return Object.values(value).filter((v) => v !== '').map((val) => {
            if (typeof val === 'object') {
                return this.compareFn(val);
            }
            return val;
        }).join(', ');
    }

    static diff(fromDate: string, toDate: string): { type: string, diff: number } {
        const to = dayjs(toDate);
        const from = dayjs(fromDate);
        const yearDiff = to.diff(from, 'year');
        const monthDiff = to.diff(from, 'month');
        const dayDiff = to.diff(from, 'day', true);
        if (monthDiff === 0) {
            return { type: 'day', diff: Math.round(dayDiff) };
        }
        if (yearDiff === 0) {
            return { type: 'month', diff: monthDiff };
        }
        return { type: 'year', diff: yearDiff };
    }

}
