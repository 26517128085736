import { Injectable } from '@angular/core';
import { AuthService, GlobalLoadingMaskService } from '@elering/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Representation, UserService } from 'gen_openapi';
import { first } from 'rxjs/operators';
import { TermsOfServiceService } from '../services/terms-of-service.service';
import { CustomUserContextService } from './custom-user-context.service';
import { Location } from "@angular/common";
import { RoutingConstants } from '../shared/constants/routing-constants';
import { StateStorageService } from "../services/state-storage.service";

@Injectable({
  providedIn: 'root'
})
export class CustomAuthService extends AuthService {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected globalLoadingMaskService: GlobalLoadingMaskService,
    private userService: UserService,
    private userContextService: CustomUserContextService,
    private termsOfService: TermsOfServiceService,
    private location: Location,
    private stateStorageService: StateStorageService
  ) {
    super(http, router, globalLoadingMaskService);
  }

  getUser() {
    // overrides parent method to use UserService defined by OpenAPI
    return this.userService.getUserDetails();
  }

  getPersonDetails() {
    // overrides parent method to use UserService defined by OpenAPI
    return this.userService.getPersonDetails();
  }

  login() {
    this.stateStorageService.storeUrl(this.router.url);
    location.href = this.location.prepareExternalUrl(`oauth2/authorization/efkp`);
  }

  setLoginDetailsSessionStorage(source) {
    super.setLoginDetailsSessionStorage(source);
    if (source.fallback_logout_endpoint_uri) {
      sessionStorage.setItem('fallback_logout_endpoint_uri', source.fallback_logout_endpoint_uri);
    }
  }

  navigateToLogout() {
    this.performLogout();
  }

  performLogout() {
    this.globalLoadingMaskService.showLoadingMask('ec.loading.logoutAction');
    this.clearSessionStorage();
    return this.http.get<void>('/logout', { observe: 'response' }).subscribe(res => {
      window.location.href = res.headers.get('Location');
    });
  }

  initialiseUser() {
    this.globalLoadingMaskService.showLoadingMask();
    this.getUser().pipe(first()).subscribe({
      next: (res) => {
        sessionStorage.setItem('user', JSON.stringify(res));
        this.initialiseTosLastUpdatedDate();
        this.setSelfRepresentation();
      },
      error: () => {
        console.log('Unable to get user details!'); // eslint-disable-line
        this.globalLoadingMaskService.hideLoadingMask();
        this.clearSessionStorage();
        this.router.navigate(['/metering-points'], { state: { keepAlerts: true } });
      }
    });
  }

  initialiseTosLastUpdatedDate(): void {
    this.termsOfService.initLastUpdatedDate().pipe(first()).subscribe({
      next: () => {
      },
      error: () => {
        console.error('Unable to get terms of service!'); // eslint-disable-line
      }
    });
  }

  isAuthenticated(): boolean {
    return !!sessionStorage.getItem('user');
  }

  private setSelfRepresentation(): void {
    this.userService.getPersonDetails(false).pipe(first()).subscribe({
      next: (person) => {
        const self = person.represents.find((r) => r.representationType === Representation.RepresentationTypeEnum.Self);
        if (self) {
          this.userContextService.setRepresentation(self, true);
        }
      },
      error: () => {
        console.error('Unable to get person details!'); // eslint-disable-line
        this.globalLoadingMaskService.hideLoadingMask();
      }
    });
  }
}
