<div class="row justify-content-start">
    <div class="col-auto" *ngFor="let item of ContentType | keyvalue : returnZero">
        <ec-radio
            id="radio-{{item.value}}"
            [label]="'mp.content.button.' + item.value"
            name="{{item.value}}"
            [(ngModel)]="contentType"
            (ngModelChange)="setType(item.value)"
            [value]="item.value"
            [disabled]="disableButton(item.value)"
            [attr.aria-disabled]="disableButton(item.value)"
            [tooltip]="disableButton(item.value) ? isDisabled : ''">
        </ec-radio>
    </div>
</div>

<ng-template #isDisabled>
    <span>{{'tooltip.chartDisabled' | translate}}</span>
</ng-template>
