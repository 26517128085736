<ec-modal [headerAlwaysVisible]="true"
          (closeClick)="modal.closeActiveModal()"
          class="modaal"
          title="{{'customerSupport.title' | translate }}">

    <ng-container *ngIf="!submitted">
        <p [innerHTML]="'customerSupport.description' | translate"></p>
        <efkp-customer-support-user-card *ngIf="loggedIn" [user]="user"></efkp-customer-support-user-card>

        <form [formGroup]="form" class="d-flex flex-column gap-3 mt-3">
            <ec-select
                formControlName="reason"
                label="customerSupport.reason.label"
                placeholder="customerSupport.reason.placeholder"
                [options]="reasons"
                [required]="true"
                [searchable]="false"
            >
            </ec-select>
            <ec-input
                type="textarea"
                formControlName="content"
                label="customerSupport.content"
                [rows]="5"
                [placeholder]="'customerSupport.contentPlaceholder' | translate"
                [required]="true">
            </ec-input>
            <div>
                <div class="fw-bold">{{ 'customerSupport.files.title' | translate }}</div>
                <efkp-file-upload [formGroup]="form" controlName="files"></efkp-file-upload>
            </div>
            <div class="d-flex gap-3">
                <ec-input
                    formControlName="email"
                    label="customerSupport.email.label"
                    placeholder="customerSupport.email.placeholder"
                    class="w-50"
                    [required]="true"
                    [customErrors]="[{code: 'email', text: 'ec.form.error.email'}]">
                </ec-input>
                <div class="mt-4 pt-3 fs-14 mb-2">{{ 'customerSupport.email.description' | translate }}</div>
            </div>

            <footer class="d-flex flex-row-reverse justify-content-between">
                <button type="submit" class="btn btn-primary"
                        (click)="submit()">{{ 'customerSupport.submit' | translate }}
                </button>
                <button class="btn btn-secondary" (click)="closeModal()">{{ 'customerSupport.cancel' | translate }}
                </button>
            </footer>
        </form>
    </ng-container>

    <ng-container *ngIf="submitted">
        <div class="d-flex flex-column align-items-center">
            <efkp-notification
                [lead]="'customerSupport.submitted' | translate"
                icon="icon-done"
                size="small">
            </efkp-notification>
            <button class="btn btn-primary float-end" (click)="closeModal()">{{ 'customerSupport.close' | translate }}
            </button>
        </div>
    </ng-container>
</ec-modal>
