/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Mandate participant role type
 */
export type MandateParticipantRoleType = 'GENERAL' | 'OPEN_SUPPLIER' | 'ENERGY_SERVICE_PROVIDER' | 'AGGREGATOR';

export const MandateParticipantRoleType = {
    General: 'GENERAL' as MandateParticipantRoleType,
    OpenSupplier: 'OPEN_SUPPLIER' as MandateParticipantRoleType,
    EnergyServiceProvider: 'ENERGY_SERVICE_PROVIDER' as MandateParticipantRoleType,
    Aggregator: 'AGGREGATOR' as MandateParticipantRoleType
};

