/* eslint-disable */
/* istanbul ignore file */
/* TODO remove disable later */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ws-timer',
    templateUrl: './timer.component.html'
})
export class TimerComponent implements OnInit {
    @Input() className = '';
    @Input() timerEnd: number;
    @Output() timerEnded: EventEmitter<any> = new EventEmitter<any>();

    timeLeft = 0;
    currentTime = new Date().getTime();

    ngOnInit() {
        this.startTimer();
        this.processTimer();
    }

    startTimer() {
        this.timeLeft = this.timerEnd - this.currentTime;
    }

    processTimer() {
        this.updateCurrentTime();
        this.updateTimer();

        if (this.timeLeft <= 0) {
            this.timeLeft = 0;
            this.onTimerEnded();
        } else {
            setTimeout(() => {
                this.processTimer();
            }, 1000);
        }
    }

    updateCurrentTime() {
        this.currentTime = new Date().getTime();
    }

    updateTimer() {
        this.timeLeft = this.timerEnd - this.currentTime;
    }

    onTimerEnded() {
        this.timerEnded.emit();
    }
}
