export * from './account.service';
import { AccountService } from './account.service';
export * from './aggregator.service';
import { AggregatorService } from './aggregator.service';
export * from './customerSupport.service';
import { CustomerSupportService } from './customerSupport.service';
export * from './dataTracker.service';
import { DataTrackerService } from './dataTracker.service';
export * from './energyPrice.service';
import { EnergyPriceService } from './energyPrice.service';
export * from './energyProvider.service';
import { EnergyProviderService } from './energyProvider.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './meteringData.service';
import { MeteringDataService } from './meteringData.service';
export * from './meteringPoint.service';
import { MeteringPointService } from './meteringPoint.service';
export * from './permission.service';
import { PermissionService } from './permission.service';
export * from './person.service';
import { PersonService } from './person.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AccountService, AggregatorService, CustomerSupportService, DataTrackerService, EnergyPriceService, EnergyProviderService, HealthService, MeteringDataService, MeteringPointService, PermissionService, PersonService, UserService];
