/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDetails { 
    /**
     * User ID
     */
    id: string;
    /**
     * User first name
     */
    firstName: string;
    /**
     * User last name
     */
    lastName: string;
    /**
     * User EIC
     */
    eic?: string;
    /**
     * User identifier
     */
    identifier: string;
    /**
     * User country
     */
    identifierCountry: string;
    /**
     * Date at which user acknowledged terms of service. Missing, if user has not acknowledged.
     */
    tosAckDateTime?: string;
}

