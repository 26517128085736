import { Injectable } from '@angular/core';
import { Utils } from '@utils';
import { ExtendedMeteringPoint } from '../models/extended-metering-point.model';

@Injectable({
    providedIn: 'root'
})
export class MeteringPointSearchService {

    private keyword: string;

    constructor() {
    }

    public search<T extends ExtendedMeteringPoint>(keyword: string, meteringPoints: T[]): T[] {
        this.keyword = keyword;
        return meteringPoints.filter((element) => this.meteringPointMatchesKeyword(element));
    }

    private meteringPointMatchesKeyword(meteringPoint: ExtendedMeteringPoint): boolean {
        return this.matchesKeyword(meteringPoint.eic)
                || this.meteringPointAddressMatchesKeyword(meteringPoint)
                || this.matchesKeyword(meteringPoint.validSupplyAgreement?.supplierName)
                || this.matchesKeyword(meteringPoint.validGridAgreement?.networkOperator)
                || this.meteringPointMetadataMatchesKeyword(meteringPoint);
    }

    private meteringPointAddressMatchesKeyword(meteringPoint: ExtendedMeteringPoint): boolean {
        if (!meteringPoint.location) {
            return false;
        }
        const location = { ...meteringPoint.location };
        location.postCode = undefined; // exclude post code from search
        return this.matchesKeyword(Utils.compareFn(location));
    }

    private meteringPointMetadataMatchesKeyword(meteringPoint: ExtendedMeteringPoint): boolean {
        if (!meteringPoint.metadata) {
            return false;
        }
        if (this.matchesKeyword(meteringPoint.metadata.name)) {
            return true;
        }
        if (meteringPoint.metadata.tags) {
            return Array.from(meteringPoint.metadata.tags.values()).filter((tag) => this.matchesKeyword(tag.name)).length > 0;
        }
        return false;
    }

    private matchesKeyword(target: string): boolean {
        return target && target.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0;
    }
}
