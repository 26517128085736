/* istanbul ignore file */

import { Component } from '@angular/core';
import { ModalService } from '@elering/common';
import { AppService } from '../../services/app.service';
import { SessionExpiryConstants } from './session-expiry.constants';

@Component({
    selector: 'ws-session-expiry-timer',
    templateUrl: './session-expiry-modal.component.html'
})
export class SessionExpiryModalComponent {
    readonly safetyBuffer = 2000;
    sessionExpiryConstants = SessionExpiryConstants;
    timeoutTimestamp: number;

    constructor(private modalService: ModalService, private appService: AppService) {
        this.timeoutTimestamp = this.calculateTimeoutTimestamp(this.appService.appSettings.sessionExpiryDialogTimeout);
    }

    close(reason?: string): void {
        this.modalService.closeActiveModal(reason);
    }

    onTimerEnded(): void {
        this.modalService.closeActiveModal(this.sessionExpiryConstants.LOGOUT);
    }

    private calculateTimeoutTimestamp(timeout: number): number {
        return (new Date().getTime() + (timeout * 1000)) - this.safetyBuffer;
    }
}
