/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Reason for the customer support request
 */
export type CustomerSupportRequestReason = 'CONSUMPTION_PRODUCTION' | 'AUTHORIZATIONS' | 'SERVICE_PROVIDERS_AND_CONTRACTS' | 'OTHER';

export const CustomerSupportRequestReason = {
    ConsumptionProduction: 'CONSUMPTION_PRODUCTION' as CustomerSupportRequestReason,
    Authorizations: 'AUTHORIZATIONS' as CustomerSupportRequestReason,
    ServiceProvidersAndContracts: 'SERVICE_PROVIDERS_AND_CONTRACTS' as CustomerSupportRequestReason,
    Other: 'OTHER' as CustomerSupportRequestReason
};

