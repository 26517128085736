import { Injectable } from '@angular/core';
import { GlobalLoadingMaskService, UserContextService } from '@elering/common';
import { Router } from '@angular/router';
import { TermsOfServiceService } from '../services/terms-of-service.service';
import { StateStorageService } from "../services/state-storage.service";

@Injectable({ providedIn: 'root' })
export class CustomUserContextService extends UserContextService {

  constructor(
    protected router: Router,
    private termsOfService: TermsOfServiceService,
    private globalLoadingMaskService: GlobalLoadingMaskService,
    private stateStorageService: StateStorageService
  ) {
    super(router);
  }

  afterRepresentationSet(): void {
    if (this.termsOfService.shouldNotifyUser()) {
      this.redirectToTermsOfService();
    } else {
      this.redirectToLoggedInView();
    }
  }

  public redirectToLoggedInView(): void {
    this.globalLoadingMaskService.hideLoadingMask();
    this.stateStorageService.navigateToStoredUrl('/metering-points');
  }

  public redirectToTermsOfService(): void {
    this.router.navigate(['/terms-of-service']);
  }
}
