/* istanbul ignore file */

import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, UserContextService } from '@elering/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

    private lang: string;
    private readonly IMAGES_URL_SEGMENT: string = 'assets/images';

    constructor(
        private translateService: TranslateService,
        private authService: AuthService,
        private userContextService: UserContextService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        this.lang = this.translateService.currentLang || 'et';

        let req = request.clone();

        if (!request.url.includes(this.IMAGES_URL_SEGMENT)) {
            req = req.clone({
                setHeaders: {
                    'Accept-Language': this.lang,
                    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache'
                }
            });
        }

        if (this.userContextService.getRepresentation()) { // represented person was selected
            const representedPersonInternalId = this.userContextService.getRepresentation().person.id;
            req = req.clone({ headers: req.headers.append('X-REPRESENTED-PERSON-ID', representedPersonInternalId) });
        }

        return next.handle(req);
    }
}
