import { Injectable } from '@angular/core';
import { AuthService, DashboardService } from '@elering/common';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardService extends DashboardService {

  constructor(
    private appService: AppService,
    private authService: AuthService
  ) {
    super();
  }

  setDefaultDashboardConfig(): void {
    this.updateDashboard({
      showSearchField: false,
      showAccessibilityMenu: this.accessibilityFeatureEnabled(),
      showHelpButton: this.helpFeatureEnabled(),
      showBreadcrumbs: true,
      breadcrumbPrefix: 'navMenu.'
    });
  }

  private accessibilityFeatureEnabled(): boolean {
    return this.appService.appSettings?.features?.accessibilityMenuEnabled ?? false;
  }

  private helpFeatureEnabled(): boolean {
    return (this.appService.appSettings?.features?.helpButtonEnabled && this.authService.isAuthenticated()) ?? false;
  }
}
