/* istanbul ignore file */

import {Component} from '@angular/core';

@Component({
    selector: 'efkp-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
}
