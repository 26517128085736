/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Energy company
 */
export interface EnergyCompany { 
    /**
     * Energy company EIC
     */
    eic: string;
    /**
     * Registry code of the energy company
     */
    registryCode: string;
    /**
     * Human-readable name of the energy company
     */
    name: string;
    /**
     * Roles
     */
    roles: Array<string>;
    /**
     * Link to the energy company
     */
    link: string;
    /**
     * Phone number of the energy company
     */
    phone: string;
    /**
     * E-mail address of the energy company
     */
    email: string;
    /**
     * Physical address of the energy company
     */
    address: string;
    /**
     * GREEN share
     */
    greenShare?: number;
    /**
     * GREEN info link
     */
    greenInfoLink?: string;
    /**
     * Privacy policy link
     */
    privacyPolicyLink?: string;
}

