/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MeteringPointType = 'REGULAR' | 'INTERNAL' | 'BORDER' | 'AGGREGATION';

export const MeteringPointType = {
    Regular: 'REGULAR' as MeteringPointType,
    Internal: 'INTERNAL' as MeteringPointType,
    Border: 'BORDER' as MeteringPointType,
    Aggregation: 'AGGREGATION' as MeteringPointType
};

