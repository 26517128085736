/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Resolution = 'fifteen_minutes' | 'one_hour' | 'one_day' | 'one_week' | 'one_month' | 'one_year';

export const Resolution = {
    FifteenMinutes: 'fifteen_minutes' as Resolution,
    OneHour: 'one_hour' as Resolution,
    OneDay: 'one_day' as Resolution,
    OneWeek: 'one_week' as Resolution,
    OneMonth: 'one_month' as Resolution,
    OneYear: 'one_year' as Resolution
};

