import {
    Component, Input, Output, EventEmitter
} from '@angular/core';
import { ContentType } from '../../models/content-type.enum';

@Component({
    selector: 'efkp-content-buttons',
    templateUrl: './content-buttons.component.html',
    styles: [
    ]
})
export class ContentButtonsComponent {

    @Input() contentType: ContentType;
    @Input() disableButtons: ContentType[];
    @Output() contentTypeEvent: EventEmitter<ContentType> = new EventEmitter();

    public setType(type: ContentType): void {
        this.contentType = type;
        this.contentTypeEvent.emit(type);
    }

    public disableButton(type: ContentType): boolean {
        if (this.disableButtons && this.disableButtons.length && this.disableButtons.includes(type)) {
            return true;
        }
        return false;
    }

    public get ContentType(): typeof ContentType {
        return ContentType;
    }

    public returnZero(): number {
        return 0;
    }

}
