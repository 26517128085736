/* istanbul ignore file */

import {
    Component, Input, Output, EventEmitter, OnDestroy, OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { MeteringPeriodDatepickerService, DatepickerMode } from '../../views/metering-point/services/metering-period-datepicker.service';

@Component({
    selector: 'efkp-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnDestroy {

    @Input() set period(date: Date[]) {
        const [from, to] = date;
        this.dateFrom = from;
        this.dateTo = to;
        this.datepickerPeriod = from;
        this.datepickerRangePeriod = date;
    }

    @Input() set mode(mode) {
        this.datepickerMode = mode;
    }

    @Input() set minDate(date) {
        this.datepickerMinDate = date;
    }

    @Input() set maxDate(date) {
        this.datepickerMaxDate = date;
    }

    @Input() set format(format) {
        this.datepickerFormat = format;
    }

    @Input() set disabled(value) {
        if (value) this.datepickerPeriod = null;
        this.datepickerDisabled = value;
    }

    @Input() placement: string = 'bottom right';

    @Output() setDateEvent: EventEmitter<Date[]> = new EventEmitter();
    @Output() setPrevDateEvent: EventEmitter<Date> = new EventEmitter();
    @Output() setNextDateEvent: EventEmitter<Date> = new EventEmitter();

    public datepickerPeriod: Date;
    public datepickerRangePeriod: Date[];
    public datepickerMode: string;
    public datepickerMinDate: Date;
    public datepickerMaxDate: Date;
    public datepickerFormat: string;
    public datepickerDisabled: boolean;

    private dateFrom: Date;
    private dateTo: Date;
    private datepickerElement: Element;

    private datepickerSubscription: Subscription;

    private langSubscription: Subscription;

    constructor(
        private datepickerService: MeteringPeriodDatepickerService,
        private localeService: BsLocaleService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        // set ngx locale
        this.localeService.use(this.translateService.currentLang);
        this.langSubscription = this.translateService.onLangChange.subscribe((config) => {
            this.localeService.use(config.lang);
        });
    }

    ngOnDestroy(): void {
        if (this.datepickerSubscription) {
            this.datepickerSubscription.unsubscribe();
        }
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }

    public weekOnShow(): void {
        this.datepickerElement = document.querySelector('bs-daterangepicker-container');
        this.datepickerElement.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            if (target.classList.contains('is-highlighted')) {
                const start = document.querySelector('bs-daterangepicker-container .select-start') as HTMLElement;
                start.click();
            }
        });
    }

    public weekOnHide(): void {
        this.datepickerElement.removeAllListeners();
    }

    public setDate(date: Date): void {
        if (date && !this.datepickerService.canSetPeriod(date)) {
            this.datepickerPeriod = new Date(this.dateFrom);
            return;
        }
        const dateArr: Date[] = [date, date];
        this.setDateEvent.emit(dateArr);
    }

    public setRangeDate(date: Date[]): void {
        this.setDateEvent.emit(date);
    }

    public isButtonDisabled(checked: Date): boolean {
        if (this.datepickerMode === DatepickerMode.ranged) {
            return true;
        }
        return this.datepickerService.isDisabled(checked, this.dateFrom, this.dateTo);
    }

    public setPrevDate(): void {
        const prev = this.datepickerService.getPreviousPeriod(this.dateFrom);
        this.setPrevDateEvent.emit(prev);
    }

    public setNextDate(): void {
        const next = this.datepickerService.getNextPeriod(this.dateFrom);
        this.setNextDateEvent.emit(next);
    }

    public get DatepickerMode(): typeof DatepickerMode {
        return DatepickerMode;
    }

}
