import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { User } from '../user';

@Component({
    selector: 'efkp-customer-support-user-card',
    templateUrl: './customer-support-user-card.component.html',
    styleUrls: ['./customer-support-user-card.component.css'],
    standalone: true,
    imports: [TranslateModule, SharedModule]
})
export class CustomerSupportUserCardComponent {
    @Input() user: User;
}
