import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { EnergyType, MeteringPoint } from 'gen_openapi';
import { Utils } from '@utils';
import { MeteringPointSourceType } from 'gen_openapi/model/meteringPointSourceType';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MeteringPointSearchService } from '../../services/metering-point-search.service';
import { MeteringPointWithDataAndMetrics } from '../../models/metering-point-with-metering-data.model';
import MeteringPointUtils from '../../metering-point-utils';

@Component({
    selector: 'mp-select',
    templateUrl: './metering-point-select.component.html',
    styleUrls: ['./metering-point-select.component.scss']
})
export class MeteringPointSelectComponent implements OnInit {

    @ViewChild('mpSelect') mpSelect?: NgSelectComponent;

    @Input() meteringPoints: MeteringPointWithDataAndMetrics[];
    @Input() currentMeteringPoints: MeteringPointWithDataAndMetrics[];
    @Input() energyType: EnergyType;
    @Input() isOpen: boolean = null;
    @Input() showFooter: boolean = false;
    @Input() hideLabel: boolean = false;

    @Output() onChangeEvent: EventEmitter<MeteringPointWithDataAndMetrics[]> = new EventEmitter();
    @Output() confirmSelectedEvent: EventEmitter<boolean> = new EventEmitter();

    public search: string;
    public selectedMeteringPoints: MeteringPointWithDataAndMetrics[] = [];
    public filteredItems: MeteringPointWithDataAndMetrics[];
    public sortedItems: MeteringPointWithDataAndMetrics[];

    constructor(private searchService: MeteringPointSearchService) {}

    ngOnInit(): void {
        this.selectedMeteringPoints = this.currentMeteringPoints;
        this.filteredItems = [...this.meteringPoints];
        this.sortMeteringPoints();
        this.selectAllForDropdownItems(this.meteringPoints);
        this.setItems();
    }

    public sortMeteringPoints(): void {
        this.sortedItems = this.filteredItems
            .sort((a, b) => Utils.compareFn(a.metadata.name || a.location).localeCompare(Utils.compareFn(b.metadata.name || b.location), 'et'))
            .sort((a, b) => this.compareByValidity(a, b))
            .sort((a, b) => this.compareBySource(a, b));
    }

    public setItems(): void {
        this.onChangeEvent.emit(this.selectedMeteringPoints);
    }

    public changeSearchFn(search: string): void {
        this.filteredItems = this.searchService.search(search, this.sortedItems);
    }

    public groupByFn = (item: MeteringPointWithDataAndMetrics) => this.getGroupName(item);

    public groupValueFn = (_: string, children: MeteringPointWithDataAndMetrics[]) => ({
        name: this.getGroupName(children[0]), total: children.length
    });

    public allSelected(): boolean {
        return this.selectedMeteringPoints?.length === this.meteringPoints?.length;
    }

    public selectAll(): void {
        if (this.allSelected()) {
            this.selectedMeteringPoints = [];
        } else {
            this.selectedMeteringPoints = this.meteringPoints;
        }
        this.setItems();
    }

    public confirmSelected(): void {
        this.confirmSelectedEvent.emit(true);
        this.mpSelect.close();
    }

    public resetSelectedMeteringPoints(): void {
        this.selectedMeteringPoints = [];
    }

    private getGroupName(mp: MeteringPointWithDataAndMetrics): string {
        if (this.isFromAccessPermission(mp)) {
            return 'mp.select.category.access';
        }
        return this.isValid(mp) ? 'mp.select.category.my' : 'mp.select.category.expired';
    }

    private compareByValidity(a: MeteringPointWithDataAndMetrics, b: MeteringPointWithDataAndMetrics): number {
        if ((this.isExpired(a) && this.isExpired(b)) || (this.isValid(a) && this.isValid(b))) {
            return 0;
        }
        if (this.isValid(a) && this.isExpired(b)) {
            return -1;
        }
        return 1;
    }

    private compareBySource(a: MeteringPointWithDataAndMetrics, b: MeteringPointWithDataAndMetrics): number {
        if (MeteringPointUtils.isFromAgreement(a) && MeteringPointUtils.isFromAgreement(b)) {
            return 0;
        }
        if (MeteringPointUtils.isFromAgreement(a) && MeteringPointUtils.isFromAccessPermission(b)) {
            return -1;
        }
        return 1;
    }

    private isValid(mp: MeteringPointWithDataAndMetrics): boolean {
        return MeteringPointUtils.isFromAgreement(mp) && MeteringPointUtils.hasValidAgreement(mp);
    }

    private isExpired(mp: MeteringPointWithDataAndMetrics): boolean {
        return MeteringPointUtils.isFromAgreement(mp) && MeteringPointUtils.hasNoValidAgreement(mp);
    }

    private isFromAccessPermission(mp: MeteringPointWithDataAndMetrics): boolean {
        return MeteringPointUtils.isFromAccessPermission(mp);
    }

    private selectAllForDropdownItems(items: MeteringPoint[]): void {
        items.forEach((element) => {
            element['groupByFn'] = 'groupByFn';
        });
    }

    public get meteringPointSourceType(): typeof MeteringPointSourceType {
        return MeteringPointSourceType;
    }
}
