<ec-modal [closable]="false">
    <h5 class="border-0 mb-0 text-nowrap">
        {{ 'sessionExpiredDialog.message' | translate }}
    </h5>

    <footer>
        <ec-button (click)="close(sessionExpiryConstants.LOGOUT)" type="secondary">
        {{ 'sessionExpiredDialog.okButton' | translate }}
        (<ws-timer
            [timerEnd]="timeoutTimestamp"
            (timerEnded)="onTimerEnded()">
        </ws-timer>)
    </ec-button>
    </footer>
</ec-modal>
