/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeteringDataAccountingInterval } from './meteringDataAccountingInterval';
import { MeteringDataTimeInterval } from './meteringDataTimeInterval';
import { MeteringDataFilteringResult } from './meteringDataFilteringResult';


export interface MeteringData { 
    /**
     * Metering point EIC
     */
    meteringPointEic: string;
    timeInterval: MeteringDataTimeInterval;
    /**
     * Resolution of metering data requested by client
     */
    requestedResolution: string;
    /**
     * Resolution of metering data returned by AVP. If `requestedResolution` != `actualResolution`, the API will convert the data to the requested resolution.
     */
    actualResolution: string;
    filteringResult?: MeteringDataFilteringResult;
    /**
     * Accounting intervals
     */
    accountingIntervals: Array<MeteringDataAccountingInterval>;
    error?: Error;
}

