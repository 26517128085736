/* istanbul ignore file */

import { Injectable, OnDestroy } from '@angular/core';
import { DashboardFooter } from '@elering/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomDashboardService } from '../overrides/custom-dashboard.service';

@Injectable({
    providedIn: 'root'
})
export class FooterService implements OnDestroy {

    private subscription: Subscription;

    constructor(
        private customDashboardService: CustomDashboardService,
        private translateService: TranslateService
    ) {

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    createFooterForUser(): void {
        this.subscription = this.translateService.onLangChange.subscribe(() => {
            this.customDashboardService.setFooter(this.getFooter());
        });
        this.customDashboardService.setFooter(this.getFooter());
    }

    private getFooter() {
        // language-specific external links
        const eleringLink = this.translateService.instant('general.footer.website.link');
        const emailLink = this.translateService.instant('general.footer.email.link');
        const aboutLink = this.translateService.instant('footer.about.link');

        const footer: DashboardFooter = {
            info: {
                title: 'Elering AS',
                infoItems: [
                    {
                        icon: 'world',
                        linkText: 'general.footer.website.label',
                        linkExternalUrl: eleringLink,
                        linkExternalUrlNewWindow: true
                    },
                    {
                        icon: 'map-pin',
                        text: 'general.footer.address'
                    },
                    {
                        icon: 'phone',
                        text: 'general.footer.phone'
                    },
                    {
                        icon: 'mail',
                        text: 'general.footer.email.label',
                        linkText: 'general.footer.email.text',
                        linkExternalUrl: emailLink
                    },
                    {
                        icon: 'clock',
                        text: 'general.footer.info'
                    }
                ],
                contactItems: []
            },
            topics: [],
            inputs: {
                pressList: false
            },
            bottomBar: [
                {
                    text: `Copyright © Elering ${new Date().getFullYear()}`
                },
                {
                    linkText: 'footer.faq',
                    linkUrl: '/faq'
                },
                {
                    linkText: 'footer.terms-of-service',
                    linkUrl: '/terms-of-service'
                },
                {
                    linkText: 'footer.cookies',
                    linkUrl: '/cookies'
                },
                {
                    linkText: 'footer.about.label',
                    linkExternalUrl: aboutLink,
                    linkExternalUrlNewWindow: true
                },
            ],
            settings: {
                bottomBarMarginTop: '3rem'
            }
        };
        return footer;
    }

}
