<div class="mp-select select-component">
  <label *ngIf="!hideLabel" class="label-title form-label" for="mp-dropdown">
        <span>{{'mp.select.label' | translate}}</span>
    </label>
    <ng-select
        #mpSelect
        labelForId="mp-dropdown"
        [items]="filteredItems"
        [(ngModel)]="selectedMeteringPoints"
        [searchable]="false"
        [clearable]="false"
        [clearOnBackspace]="false"
        [closeOnSelect]="false"
        [multiple]="true"
        [isOpen]="isOpen"
        dropdownPosition="bottom"
        placeholder="{{'mp.select.placeholder' | translate}}"
        [groupBy]="groupByFn"
        [groupValue]="groupValueFn"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        (change)="setItems()"
        notFoundText="{{'mp.select.notFoundText' | translate}}"
    >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value">
                <span class="ng-value-label">
                    {{items.length}} {{'mp.select.selected' | translate}}
                </span>
            </div>
        </ng-template>
        <ng-template ng-header-tmp>
            <div class="search">
                <ec-input
                    id="mp-search"
                    class="search-input"
                    [noLabel]="true"
                    placeholder="mp.label.search"
                    [(ngModel)]="search"
                    (ngModelChange)="changeSearchFn($event)">
                </ec-input>
            </div>
            <div class="ng-dropdown-panel-items" *ngIf="meteringPoints">
                <div class="ng-option-marked ng-optgroup {{allSelected() ? 'ng-option-selected' : ''}}" (click)="selectAll()">
                    <span class="select-all">{{'mp.select.selectAll' | translate}} ({{meteringPoints.length}})</span>
                </div>
            </div>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item">
            <a collapse href="javascript:void(0)" class="me-2 collapse-icon"></a>
            <span class="align-middle">{{item.name | translate}} ({{item.total}})</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search">
                <ng-container *ngIf="item.source.type === meteringPointSourceType.Permission">
                    {{item.source.person.name}} /
                </ng-container>
                <ng-container *ngIf="item.metadata.name; else address">{{item.metadata.name}}</ng-container>
                <ng-template #address>
                    <mp-metering-address [location]="item.location"></mp-metering-address>
                </ng-template>
                <span class="eic">{{item.eic}}</span>
            </span>
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="showFooter">
            <button class="btn btn-sm btn-primary" (click)="confirmSelected()" [disabled]="selectedMeteringPoints.length === 0">
                <span>{{'mp.select.confirm' | translate}}</span>
                <ec-icon icon="chevron-right" class="d-inline-block"></ec-icon>
            </button>
        </ng-template>
    </ng-select>
</div>
