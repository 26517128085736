/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Location -- address and coordinates
 */
export interface MeteringPointLocation { 
    county?: string;
    municipality?: string;
    locality?: string;
    streetAddress?: string;
    postCode?: string;
    xCoordinate?: string;
    yCoordinate?: string;
}

