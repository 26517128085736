/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of data that was queried
 */
export type TrackedDataType = 'METERING_POINT_DATA' | 'METERING_DATA' | 'JOINT_INVOICE' | 'NETWORK_BILL' | 'CUSTOMER' | 'AGREEMENT' | 'REPORT' | 'BALANCE_SETTLEMENT_POINT';

export const TrackedDataType = {
    MeteringPointData: 'METERING_POINT_DATA' as TrackedDataType,
    MeteringData: 'METERING_DATA' as TrackedDataType,
    JointInvoice: 'JOINT_INVOICE' as TrackedDataType,
    NetworkBill: 'NETWORK_BILL' as TrackedDataType,
    Customer: 'CUSTOMER' as TrackedDataType,
    Agreement: 'AGREEMENT' as TrackedDataType,
    Report: 'REPORT' as TrackedDataType,
    BalanceSettlementPoint: 'BALANCE_SETTLEMENT_POINT' as TrackedDataType
};

