/* istanbul ignore file */

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'energyAmount'
})
export class EnergyAmountPipe implements PipeTransform {
    static readonly units: string[] = ['kWh', 'MWh', 'GWh', 'TWh'];
    static readonly divThreshold: number = 1000000;

    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: number, digitsInfo?: string, locale?: string): string | null {
        if (value === undefined || value === null) {
            return null;
        }
        const unit = this.getUnit(value);
        const formattedValue = this.decimalPipe.transform(EnergyAmountPipe.toThreshold(value), digitsInfo, locale);
        return `${formattedValue} ${unit}`;
    }

    private getUnit(value: number): string {
        const energyUnits = EnergyAmountPipe.units;
        let currentValue = value;
        let unitIdx = 0;
        while (currentValue !== undefined && currentValue >= EnergyAmountPipe.divThreshold) {
            currentValue /= 1000;
            unitIdx += 1;
        }
        return unitIdx < energyUnits.length ? energyUnits[unitIdx] : energyUnits[energyUnits.length - 1];
    }

    private static toThreshold(value: number): number {
        let currentValue = value;
        while (currentValue !== undefined && currentValue >= EnergyAmountPipe.divThreshold) {
            currentValue /= 1000;
        }
        return currentValue;
    }
}
