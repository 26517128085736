import { inject, Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class StateStorageService {
  private previousUrlKey = 'previousUrl';
  private router = inject(Router);

  storeUrl(url: string): void {
    sessionStorage.setItem(this.previousUrlKey, JSON.stringify(url));
  }

  getUrl(): string | null {
    const url = sessionStorage.getItem(this.previousUrlKey);
    return url ? (JSON.parse(url) as string | null) : url;
  }

  navigateToStoredUrl(fallbackUrl?: string): void {
    const url = this.getUrl() || fallbackUrl;
    if (url) {
      this.clearUrl();
      this.router.navigateByUrl(url);
    }
  }

  clearUrl(): void {
    sessionStorage.removeItem(this.previousUrlKey);
  }
}
