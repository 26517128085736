import * as dayjs from 'dayjs';
import {
    EnergyPriceValue,
    EnergyType,
    MeteringData, MeteringDataAccountingInterval,
    MeteringDataFilteringResult, MeteringPoint
} from 'gen_openapi';
import cloneDeep from 'lodash-es/cloneDeep';
import { EnergyUnit } from 'src/app/models/energy-unit.enum';
import { MeteringPointSourceType } from 'gen_openapi/model/meteringPointSourceType';
import { MeteringPointWithData, MeteringPointWithDataAndMetrics } from './models/metering-point-with-metering-data.model';

export default class MeteringPointUtils {

    static addressAsString(mp: MeteringPoint): string {
        if (mp.location) {
            const l = mp.location;
            return [l.streetAddress, l.locality, l.municipality, l.county].filter((e) => e !== undefined).join(', ');
        }
        return undefined;
    }

    static toMap<KeyType, ValueType>(
        data: MeteringData[],
        identityFn: (d: MeteringData, i: MeteringDataAccountingInterval) => ValueType,
        accumulatorFn: (d: MeteringData, a: ValueType, b: MeteringDataAccountingInterval) => ValueType,
        keyFn: (i: MeteringDataAccountingInterval) => KeyType
    ): Map<KeyType, ValueType> {
        // Create a new map to store the results
        const map = new Map<KeyType, ValueType>();
        // Iterate over each element in the data array
        data.forEach((d) => {
            // For each element, iterate over its accounting intervals
            d.accountingIntervals.forEach((interval) => {
                // Determine the key for the current interval
                const key = keyFn(interval);
                // Check if the key is already in the map
                const intervalInMap = map.get(key);
                // If the key is not in the map, add it with the value returned by the identity function
                // If the key is already in the map, update its value with the result of the accumulator function
                map.set(key, intervalInMap === undefined ? identityFn(d, interval) : accumulatorFn(d, intervalInMap, interval));
            });
        });
        return map;
    }

    // The method is intended to add the values of the two input intervals together, element by element.
    static add(i1: MeteringDataAccountingInterval, i2: MeteringDataAccountingInterval): MeteringDataAccountingInterval {
        const add = (a?: number, b?: number) => (b ? (a || 0) + b : a);
        function sum(price1: EnergyPriceValue, price2: EnergyPriceValue): EnergyPriceValue {
            return {
                centsPerKwh: add(price1.centsPerKwh, price2.centsPerKwh),
                centsPerKwhWithVat: add(price1.centsPerKwhWithVat, price2.centsPerKwhWithVat),
                eurPerMwh: add(price1.eurPerMwh, price2.eurPerMwh),
                eurPerMwhWithVat: add(price1.eurPerMwhWithVat, price2.eurPerMwhWithVat)
            };
        }
        i1.consumptionKwh = add(i1.consumptionKwh, i2.consumptionKwh);
        i1.consumptionM3 = add(i1.consumptionM3, i2.consumptionM3);
        i1.productionKwh = add(i1.productionKwh, i2.productionKwh);
        i1.productionM3 = add(i1.productionM3, i2.productionM3);
        i1.productionPriceTotal = sum(i1.productionPriceTotal, i2.productionPriceTotal);
        i1.consumptionPriceTotal = sum(i1.consumptionPriceTotal, i2.consumptionPriceTotal);
        return i1;
    }

    static sum(price1: EnergyPriceValue, price2: EnergyPriceValue): EnergyPriceValue {
        const add = (a?: number, b?: number) => (b ? (a || 0) + b : a);
        return {
            centsPerKwh: add(price1.centsPerKwh, price2.centsPerKwh),
            centsPerKwhWithVat: add(price1.centsPerKwhWithVat, price2.centsPerKwhWithVat),
            eurPerMwh: add(price1.eurPerMwh, price2.eurPerMwh),
            eurPerMwhWithVat: add(price1.eurPerMwhWithVat, price2.eurPerMwhWithVat)
        };
    }

    // Assumes all data has same metering period
    static sumUp(data: MeteringData[]): MeteringData {
        if (data.length === 0) {
            return undefined;
        }
        // Create a map of the accounting intervals, grouped by their fromTimestamp
        const sum = MeteringPointUtils.toMap(
            data,
            // Use the identity function to create a new interval object from the input interval
            (d, i) => cloneDeep(i),
            // Use the add method to sum the values of the two intervals
            (d, a, b) => MeteringPointUtils.add(a, b),
            // Use the fromTimestamp as the key for the map
            (i) => i.fromTimestamp
        );
        // Convert the map to an array of intervals, and sort the array by fromTimestamp
        const accountingIntervals = Array.from(sum.values())
            .sort((a, b) => new Date(a.fromTimestamp).getTime() - new Date(b.fromTimestamp).getTime());
        // Return a new MeteringData object with the sum of the intervals
        return {
            meteringPointEic: 'sum',
            timeInterval: data[0].timeInterval,
            requestedResolution: data[0].requestedResolution,
            actualResolution: data[0].actualResolution,
            accountingIntervals
        } as MeteringData;
    }

    static hasProductionUnit(meteringPoint: MeteringPoint): boolean {
        return meteringPoint.energyType !== EnergyType.Gas;
    }

    static dataHasBeenFiltered(data: MeteringPointWithData[]): boolean {
        return data.find((d) => d.meteringData.filteringResult
            && (d.meteringData.filteringResult === MeteringDataFilteringResult.Partial
            || d.meteringData.filteringResult === MeteringDataFilteringResult.Full)) !== undefined;
    }

    static hasMeteringData(data: MeteringData, energyUnit?: EnergyUnit): boolean {
        return data.accountingIntervals.find((i) => MeteringPointUtils.hasData(i, energyUnit)) !== undefined;
    }

    static hasData(interval: MeteringDataAccountingInterval, energyUnit?: EnergyUnit): boolean {
        const hasKwh = interval?.consumptionKwh !== undefined || interval?.productionKwh !== undefined;
        const hasM3 = interval?.consumptionM3 !== undefined || interval?.productionM3 !== undefined;
        if (!energyUnit) {
            return hasKwh || hasM3;
        }
        if (energyUnit === EnergyUnit.M3) {
            return hasM3;
        }
        return hasKwh;
    }

    static existsHourlyMeteredGasMeteringPoint(meteringPoints: MeteringPointWithData[], energyUnit: EnergyUnit): boolean {
        return meteringPoints.find((mp) => {
            if (MeteringPointUtils.isGasMeteringPointHourlyMetered(mp.meteringData, energyUnit)) {
                return mp;
            }
            return undefined;
        }) !== undefined;
    }

    static isGasMeteringPointHourlyMetered(meteringData: MeteringData, energyUnit: EnergyUnit): boolean {
        return MeteringPointUtils.hasHourlyDataOnlyAtHours(meteringData, energyUnit, [7]);
    }

    static hasValidAgreement(mp: MeteringPointWithDataAndMetrics): boolean {
        return mp.validGridAgreement !== undefined;
    }

    static hasNoValidAgreement(mp: MeteringPointWithDataAndMetrics): boolean {
        return mp.validGridAgreement === undefined;
    }

    static isFromAgreement(mp: MeteringPointWithDataAndMetrics): boolean {
        return mp.source.type === MeteringPointSourceType.Agreement;
    }

    static isFromAccessPermission(mp: MeteringPointWithDataAndMetrics): boolean {
        return mp.source.type === MeteringPointSourceType.Permission;
    }

    private static hasHourlyDataOnlyAtHours(meteringData: MeteringData, energyUnit: EnergyUnit, hours: number[]): boolean {
        let atHours = false;
        let notAtHours = true;
        meteringData.accountingIntervals.filter((i) => MeteringPointUtils.hasData(i, energyUnit)).forEach((i) => {
            const from = dayjs(i.fromDateTime);
            if (hours.includes(from.hour())) {
                atHours = true;
            } else {
                notAtHours = false;
            }
        });
        return atHours && !notAtHours;
    }
}
