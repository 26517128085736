import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingConstants } from '../shared/constants/routing-constants';
import { StateStorageService } from "./state-storage.service";

@Injectable({
  providedIn: 'root'
})
export class GrantService {
  private router = inject(Router);
  private stateStorageService = inject(StateStorageService);

  public isGrantPage() {
    return this.router.url.startsWith(`/${RoutingConstants.GRANT_PATH}`);
  }

  public isRedirectingToGrantPage() {
    return (this.router.url === `/${RoutingConstants.TERMS_OF_SERVICE_PATH}`
        || this.router.url === `/${RoutingConstants.POST_LOGIN_PATH}`)
      && this.stateStorageService.getUrl()?.startsWith(`/${RoutingConstants.GRANT_PATH}`);
  }
}
