/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDetails, UserService } from 'gen_openapi';
import { Observable } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class TermsOfServiceService {
    contentPath: string = '/assets/i18n/terms-of-service';

    public lastUpdatedDate: dayjs.Dayjs = dayjs();

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    initLastUpdatedDate(): Observable<string | Date> {
        return this.http.get(`${this.contentPath}/date`, { responseType: 'text' }).pipe(
            tap({
                next: (date) => {
                    this.lastUpdatedDate = dayjs(date);
                }
            })
        );
    }

    acknowledge(onCompleted: () => void): void {
        this.userService.ackTermsOfService({ version: this.lastUpdatedDate.format('YYYY-MM-DD') })
            .pipe(first()).subscribe({
                next: () => {
                    // success, reload user details to get latest TOS ack date.
                    this.userService.getUserDetails().subscribe({
                        next: (user) => {
                            sessionStorage.setItem('user', JSON.stringify(user));
                        },
                        error: () => {},
                        complete: () => {
                            onCompleted();
                        }
                    });
                }
            });
    }

    userHasNotAcknowledged(): boolean {
        return !this.userTosAckDateTime();
    }

    termsHaveChangedSinceUserLastAcknowledged(): boolean {
        const tosAckDateTime = this.userTosAckDateTime();
        return tosAckDateTime && this.lastUpdatedDate.isAfter(dayjs(tosAckDateTime));
    }

    shouldNotifyUser(): boolean {
        return this.userHasNotAcknowledged() || this.termsHaveChangedSinceUserLastAcknowledged();
    }

    private userTosAckDateTime(): string {
        const userObj = sessionStorage.getItem('user');
        if (userObj) {
            const user = JSON.parse(userObj) as UserDetails;
            return user.tosAckDateTime;
        }
        return null;
    }
}
