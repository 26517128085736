<ec-modal [closable]="false">
    <h5 class="border-0 mb-0 text-nowrap">
        {{ 'sessionExpiryDialog.message' | translate }}

        <ws-timer
            [timerEnd]="timeoutTimestamp"
            (timerEnded)="onTimerEnded()"
        ></ws-timer>
    </h5>

    <footer>
        <ec-button (click)="close(sessionExpiryConstants.LOGOUT)"
                   type="secondary"
        >{{ 'ec.general.logOut' | translate }}</ec-button>

        <ec-button (click)="close(sessionExpiryConstants.EXTEND)"
                   type="primary"
        >{{ 'sessionExpiryDialog.extend' | translate }}</ec-button>
    </footer>
</ec-modal>
