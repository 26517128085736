/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Error response
 */
export interface ModelError { 
    /**
     * Enique error tracking identifier
     */
    id: string;
    /**
     * HTTP status code
     */
    status: number;
    /**
     * Hierachical classifier of error type + specific code
     */
    code: string;
    /**
     * Optional human-readable error message for debugging purposes
     */
    title?: string;
    /**
     * Optional longer explanation of the error cause (i.e. stacktrace)
     */
    detail?: string;
}

