import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, LogoType, NavItem } from '@elering/common';
import { CustomDashboardService } from '../overrides/custom-dashboard.service';
import { CustomUserContextService } from '../overrides/custom-user-context.service';
import { AccessPermissionRoles } from '../views/access-permission/models/access-permission-roles.enum';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class UserMenuService {
    constructor(
        private customDashboardService: CustomDashboardService,
        private translateService: TranslateService,
        private appService: AppService,
        private auth: AuthService,
        private userCtx: CustomUserContextService
    ) {}

    adjustMenuForUser(): void {
        const menuItems: NavItem[] = [{
            title: 'navMenu.metering-points',
            url: '/metering-points'
        }, {
            title: 'navMenu.metering-data-consumption',
            url: '/metering-data-consumption'
        }, {
            title: 'navMenu.metering-data-production',
            url: '/metering-data-production'
        }, {
            title: 'navMenu.roles-and-rights',
            url: '/roles-and-rights'
        }, {
            title: 'navMenu.exchange-prices',
            url: '/exchange-prices'
        }, {
            title: 'navMenu.services',
            url: '/services'
        }];

        if (!this.userHasLimitedRepresentation()) {
            menuItems.push({
                title: 'navMenu.data-tracker',
                url: '/data-tracker'
            });
        }

        if (this.appService.appSettings?.features?.accountSettingsEnabled && this.auth.isAuthenticated()) {
            menuItems.push({
                title: 'navMenu.account-settings',
                url: '/account-settings'
            });
        }

        this.translateService.stream('externalLinks').subscribe((link) => {
            Object.keys(link).forEach((key) => {
                menuItems.push({
                    title: `externalLinks.${key}.title`,
                    url: link[key].url,
                    externalUrl: 'true'
                });
            });
        }).unsubscribe();

        this.customDashboardService.setMenu(menuItems);
        this.customDashboardService.setLogo({ type: LogoType.ELERING_LOGO_WITH_EMBLEM, url: '/' });
        this.customDashboardService.setReturnButton({ text: 'general.returnButton', url: 'https://elering.ee' });
    }

    userHasLimitedRepresentation() {
        const representation = this.userCtx.getRepresentation();
        return representation?.roles?.includes(AccessPermissionRoles.Limited);
    }
}
