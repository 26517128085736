/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * API key list item
 */
export interface ApiKeyListItem { 
    clientId?: string;
    name?: string;
    createdAt?: string;
    lastUsedAt?: string;
    validFrom?: string;
    validTo?: string;
    meteringPointEics?: Array<string>;
    status?: ApiKeyListItem.StatusEnum;
}
export namespace ApiKeyListItem {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'EXPIRED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Expired: 'EXPIRED' as StatusEnum
    };
}


