/* istanbul ignore file */

import {
    Component, Input, OnInit, OnDestroy, Injector, ViewChild, ElementRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
    selector: 'efkp-form-datepicker',
    templateUrl: './form-datepicker.component.html',
    styleUrls: ['./form-datepicker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: FormDatepickerComponent,
        multi: true
    }],
})
export class FormDatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @ViewChild('dateInput', { static: true }) dateInputElementRef: ElementRef;

    @Input() label: string;
    @Input() required: boolean;
    @Input() placement: string;
    @Input() formGroupError: Record<string, boolean>;

    public value: Date;
    public ngControl: NgControl;

    public onChange: (string) => void;
    public onTouched: (string) => void;

    public dpConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'DD.MM.YYYY',
        customTodayClass: 'today-class',
        useUtc: false,
        showWeekNumbers: false
    };

    private langSubscription: Subscription;

    constructor(
        private localeService: BsLocaleService,
        private translateService: TranslateService,
        private injector: Injector
    ) { }

    writeValue(value): void {
        if (!value) {
            setTimeout(() => {
                this.value = null;
            });
            return;
        }
        this.value = new Date(value);
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    onValueChange(value: string): void {
        if (Number.isNaN(Date.parse(value))) {
            this.writeValue(null);
            this.onChange(null);
            this.dateInputElementRef.nativeElement.value = null;
            return;
        }
        this.onChange(value);
    }

    ngOnInit(): void {
        this.localeService.use(this.translateService.currentLang);
        this.langSubscription = this.translateService.onLangChange.subscribe((config) => {
            this.localeService.use(config.lang);
        });
        this.ngControl = this.injector.get(NgControl);
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }

}
