/* istanbul ignore file */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
import Accessibility from 'highcharts/modules/accessibility';

HC_boost(Highcharts);
Accessibility(Highcharts)

@Component({
    selector: 'efkp-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {

    @Input() series: Highcharts.SeriesOptionsType[];
    @Input() tooltipOptions: Highcharts.TooltipOptions;
    @Input() xAxis: Highcharts.XAxisOptions;
    @Input() yAxis: Highcharts.YAxisOptions[];
    @Input() legend: Highcharts.LegendOptions = { enabled: false };

    public Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: Highcharts.Options;
    public update: boolean = false;

    private subscription: Subscription;

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
        this.chartOptions = this.options();
        this.setChartTranslations();
        this.subscription = this.translateService.onLangChange.subscribe(() => {
            this.setChartTranslations();
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public updateSeries(series: Highcharts.SeriesOptionsType[]): void {
        this.chartOptions.series = series;
        this.update = true;
    }

    public updateTooltip(tooltip: Highcharts.TooltipOptions): void {
        this.chartOptions.tooltip = tooltip;
        this.update = true;
    }

    public updateXAxis(xAxis: Highcharts.XAxisOptions): void {
        this.chartOptions.xAxis = xAxis;
        this.update = true;
    }

    public updateYAxis(yAxis: Highcharts.YAxisOptions[]): void {
        this.chartOptions.yAxis = yAxis;
        this.update = true;
    }

    private setChartTranslations(): void {
        Highcharts.setOptions({
            lang: {
                resetZoom: this.translateService.instant('chart.resetZoom')
            }
        });
    }

    private options(): Highcharts.Options {
        return {
            boost: {
                useGPUTranslations: true,
                seriesThreshold: 50,
                allowForce: false
            },
            chart: {
                styledMode: true,
                spacingBottom: 100,
                zooming: {
                    type: 'x'
                }
            },
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            series: this.series,
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                },
                column: {
                    centerInCategory: true,
                    minPointLength: 2
                },
                line: {
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2
                    }
                },
                area: {
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2
                    }
                }
            },
            tooltip: this.tooltipOptions,
            legend: this.legend
        };
    }

}
