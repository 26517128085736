/* istanbul ignore file */

import { Injectable } from '@angular/core';
import { EnergyCompany, EnergyProviderService, EnergyType } from 'gen_openapi';
import {forkJoin, Observable, of} from 'rxjs';
import { tap, first, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EnergyCompaniesService {
    private companies: Map<EnergyType, EnergyCompany[]> = new Map();

    constructor(private api: EnergyProviderService) { }

    public load(): Observable<any> {
        this.companies.clear();

        return forkJoin([
                this.loadFromApi(EnergyType.Electricity).pipe(first(), catchError((err) => of(err))),
                this.loadFromApi(EnergyType.Gas).pipe(first(), catchError((err) => of(err)))
        ]);
    }

    public get(type: EnergyType): Observable<EnergyCompany[]> {
        if (this.companies.has(type)) {
            return of(this.companies.get(type));
        }
        return this.loadFromApi(type);
    }

    private loadFromApi(type: EnergyType): Observable<EnergyCompany[]> {
        return this.api.getEnergyCompanies(type).pipe(
            tap((companies) => this.companies.set(type, companies))
        );
    }
}
