/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Validity time
 */
export interface ValidityTime { 
    /**
     * Validity start time in UTC
     */
    validFrom: string;
    /**
     * Validity end time in UTC
     */
    validTo: string;
}

