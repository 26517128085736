import { Injectable } from '@angular/core';
import { HelpClickHandler, ModalService } from '@elering/common';
import { CustomerSupportModalComponent } from '../views/customer-support/customer-support-modal.component';

@Injectable({
    providedIn: 'root'
})
export class CustomHelpClickHandler extends HelpClickHandler {

    constructor(private modal: ModalService) {
        super();
    }

    handleClick(): void {
        this.modal.showModal({
            content: CustomerSupportModalComponent,
            modalOptions: {
                class: 'modal-xl help-modal',
            }
        });
    }
}
