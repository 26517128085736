/* istanbul ignore file */
/* eslint-disable */

import { Injectable, Renderer2, Inject, ApplicationRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppService } from './app.service';

declare namespace cookiebot {
  class Cookiebot {
    show(): void;

    dialog: {
        visible: boolean;
    }
  }
}

declare const Cookiebot: cookiebot.Cookiebot;
const COOKIEBOT_SCRIPT_ID: string = 'Cookiebot';
const COOKIEBOT_DECLARATION_SCRIPT_ID: string = 'CookieDeclaration';
const DATA_CULTURE_ATTRIBUTE = 'data-culture';

@Injectable({
    providedIn: 'root'
})
export class CookiebotService {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private appService: AppService,
        private ref: ApplicationRef,
    ) { }

    init(renderer: Renderer2, lang: string): void {
        if (!this.appService.appSettings?.cookiebot?.enabled) {
            return;
        }

        const cookiebotScript = renderer.createElement('script');
        cookiebotScript.id = COOKIEBOT_SCRIPT_ID;
        cookiebotScript.type = 'text/javascript';
        cookiebotScript.src = `${this.appService.appSettings.cookiebot.url}/uc.js`;
        cookiebotScript.setAttribute('data-cbid', this.appService.appSettings.cookiebot.domainGroupId);
        cookiebotScript.setAttribute('data-blockingmode', 'manual');
        cookiebotScript.setAttribute('data-culture', lang);
        renderer.appendChild(this.document.head, cookiebotScript);

        const cookiebotConfigScript = renderer.createElement('script');
        cookiebotConfigScript.id = 'CookiebotConfig';
        cookiebotConfigScript.type = 'text/javascript';
        cookiebotConfigScript.src = `/assets/scripts/cookiebot-config.js`;
        renderer.appendChild(this.document.head, cookiebotConfigScript);

        window.addEventListener('CookiebotOnLoad', () => {
            this.ref.tick();
        });
    }

    changeLanguage(renderer: Renderer2, lang: string): void {
        const cookieBotScript = renderer.selectRootElement('#' + COOKIEBOT_SCRIPT_ID);
        cookieBotScript.setAttribute(DATA_CULTURE_ATTRIBUTE, lang);

        const cookieBotDeclarationScript = document.querySelector('#' + COOKIEBOT_DECLARATION_SCRIPT_ID);
        if (cookieBotDeclarationScript) {
            cookieBotDeclarationScript.setAttribute(DATA_CULTURE_ATTRIBUTE, lang);
            this.removeDialogConfigurationScripts();

            if (Cookiebot.dialog?.visible) {
                Cookiebot.show();
            }
        }

        if (!this.cookiesAccepted() && Cookiebot.dialog?.visible) {
            this.removeDialogConfigurationScripts();
            Cookiebot.show();
        }
    }

    cookiesAccepted(): boolean {
        return document.cookie.includes('CookieConsent=');
    }

    removeDialogConfigurationScripts(): void {
        document.querySelectorAll("[src*='cc.js']").forEach(e => e.remove());
        document.querySelectorAll("[src*='cdreport.js']").forEach(e => e.remove());
    }
}
