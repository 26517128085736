/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AgreementStatus } from './agreementStatus';


/**
 * Supply agreement
 */
export interface SupplyAgreement { 
    supplierEic: string;
    supplierName: string;
    status: AgreementStatus;
    firstDate: string;
    lastDate?: string;
    contractTerminationPenalty?: string;
    generalService?: boolean;
}

