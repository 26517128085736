/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MeteringType = 'REMOTE_READING' | 'VIRTUAL' | 'NON_REMOTE_READING';

export const MeteringType = {
    RemoteReading: 'REMOTE_READING' as MeteringType,
    Virtual: 'VIRTUAL' as MeteringType,
    NonRemoteReading: 'NON_REMOTE_READING' as MeteringType
};

