<section class="step-container">
    <ul class="step-progress-bar">
        <li *ngFor="let step of steps; let i = index"
            [ngClass]="{'completed': step.completed, 'active' : selectedIndex === i }">
        </li>
    </ul>
    <div class="step-inner">
        <header class="step-header">
            <h2>
                <span *ngIf="steps.length > 1">{{ selectedIndex + 1 }}/{{ steps.length }}</span>
                <ng-container [ngTemplateOutlet]="selected.stepLabel.template"></ng-container>
            </h2>
        </header>
        <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </div>
</section>
